// Fluid width columns
// .grid {
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//   /* This is better for small screens, once min() is better supported */
//   /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
//   gap: 1rem;
// }

import { Card, CardHeader, Divider, CardContent, Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import LoadingIndicator from './shared/LoadingIndicator';
import { useRegattaConfig, useSelectedRegatta } from './shared/RegattaState';

interface CardWrapperProps {
  title: string;
  cols?: number;
}

/**
 * Boilerplate for a Card to show.  A LoadingIndicator is shown if the selected regatta
 * or data config has not been initialized.
 *
 * @param props
 * @returns
 */
const CardWrapper: FC<PropsWithChildren<CardWrapperProps>> = (props) => {
  const [regattaConfig] = useRegattaConfig();
  const [selectedRegatta] = useSelectedRegatta();

  return (
    <Card sx={{ borderRadius: 4, textAlign: 'center', backgroundColor: '#f8f8f8' }}>
      <CardHeader title={props.title} sx={{ padding: '10px' }} />
      <Divider variant="middle" />
      <CardContent sx={{ padding: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: props.cols === 1 ? '1fr' : 'min-content 1fr',
              gridGap: '0px',
              padding: '1em',
            }}
          >
            {selectedRegatta && regattaConfig ? props.children : <LoadingIndicator />}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardWrapper;
