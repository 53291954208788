import React, { FC } from 'react';
import { useBaseTimingWaypoints, useTimeSystems, useSelectedRegatta } from '../shared/RegattaState';
import { Select, MenuItem, FormControl, InputLabel, Theme, SelectChangeEvent } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { niceWaypoint } from '../shared/StringUtil';
import makeStyles from '@mui/styles/makeStyles';
import Util from './Util';

const eventColor = '#f44336';
const globalColor = '#5468ff';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
    selectItem: {},
    inheritItem: { color: globalColor },
    container: {
      display: 'flex',
      flexDirection: 'row',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#888',
      borderRadius: 8,
      justifyContent: 'center',
    },
  })
);

interface TimeSystemProps {
  eventNum?: string;
}

const TimeSystemSelection: FC<TimeSystemProps> = ({ eventNum }) => {
  const classes = useStyles();
  const [timingSystems, setTimingSystems] = useTimeSystems();
  const [{ baseWaypoints, baseAlternates }] = useBaseTimingWaypoints();
  const [regattaId] = useSelectedRegatta();
  const globalSystem = timingSystems.system || {};
  const timingSystem = eventNum === undefined ? timingSystems.system : timingSystems.events[eventNum] || {};

  const handleChange = (wp: string) => (event: SelectChangeEvent<string>) => {
    let value: string | undefined = event.target.value;
    value = value.replace(/ /g, '_');
    wp = wp.replace(/ /g, '_');
    if (eventNum) {
      const newEvent = { ...timingSystem, [wp]: value };
      if (value === 'Inherit') {
        newEvent[wp] = null as unknown as string; // trick to force firebase to delete the entry
      }

      setTimingSystems({ ...timingSystems, events: { ...timingSystems.events, [String(eventNum)]: newEvent } });
      Util.setEventTimingSystem(regattaId, eventNum, newEvent)
        .then()
        .catch(() => {});
    } else {
      const system = { ...timingSystems.system, [wp]: value };
      setTimingSystems({ ...timingSystems, system });
      Util.setGlobalTimingSystem(regattaId, system)
        .then()
        .catch(() => {});
    }
  };

  if (Object.keys(baseAlternates).length === 0) {
    return <></>;
  }
  return (
    <div className={classes.container}>
      {baseWaypoints.map((wp) => (
        <div key={wp}>
          <FormControl className={classes.formControl} margin="dense">
            {/* Set Time System z index to 0 from 1.  This prevents it from showing up in headers on scroll */}
            <InputLabel id="simple-select-label" sx={{ zIndex: 0 }}>
              {`${wp} System`}
            </InputLabel>
            <Select
              variant="standard"
              className="noprint"
              value={timingSystem[wp] || globalSystem[wp] || wp}
              onChange={handleChange(wp)}
              style={{
                fontSize: eventNum === undefined ? '1.0em' : '0.8em',
                color: eventNum === undefined || !timingSystem[wp] ? globalColor : eventColor,
                marginLeft: '2%',
                marginRight: '2%',
                textOverflow: 'ellipsis',
                marginTop: '0.4em',
              }}
            >
              {eventNum !== undefined && (
                <MenuItem key="inherit" value="Inherit" className={classes.inheritItem}>
                  Inherit
                </MenuItem>
              )}
              {baseAlternates[wp].map((waypoint) => {
                return (
                  <MenuItem key={waypoint} value={waypoint} className={classes.selectItem}>
                    {niceWaypoint(waypoint)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      ))}
    </div>
  );
};

export default TimeSystemSelection;
