import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useTrackingStations, useQRCodes, useRegattaInfo, useRegattaTitle } from './shared/UseResults';
import { useRegattaConfig, useSelectedRegatta, useTimingWaypoints } from './shared/RegattaState';
import LoadingIndicator from './shared/LoadingIndicator';
import { FC, PropsWithChildren, useState } from 'react';
import Util from './shared/Util';
import QRCode from 'react-qr-code';
import { v1 as uuidv1 } from 'uuid';
import { QRCodeInfo } from './shared/CrewTimerTypes';
import { Buffer } from 'buffer';
import KeyMap from './shared/KeyMap';
import stringify from 'fast-json-stable-stringify';

interface CardWrapperProps {
  title: string;
  cols?: number;
}

/**
 * Boilerplate for a Card to show.  A LoadingIndicator is shown if the selected regatta
 * or data config has not been initialized.
 *
 * @param props
 * @returns
 */
const CardWrapper: FC<PropsWithChildren<CardWrapperProps>> = (props) => {
  const [regattaConfig] = useRegattaConfig();
  const [selectedRegatta] = useSelectedRegatta();
  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: 0,
        border: 1,
        textAlign: 'center',
        backgroundColor: '#fff',
        width: 300,
        paddingBottom: 0,
      }}
      className="enlarge"
    >
      <CardHeader title={props.title} sx={{ padding: '10px' }} />
      <Divider variant="middle" />
      <CardContent sx={{ padding: 0, '&:last-child': { pb: 0 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: props.cols === 1 ? '1fr' : 'min-content 1fr',
              gridGap: '0px',
              padding: '1em',
            }}
          >
            {selectedRegatta && regattaConfig ? props.children : <LoadingIndicator />}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const MobileLinkCard = ({
  title,
  mobilePin,
  stationType,
  accessLevel,
  settings,
}: {
  title: string;
  mobilePin: string;
  stationType: string;
  accessLevel: string;
  settings: KeyMap;
}) => {
  const [regattaId] = useSelectedRegatta();
  const [qrCodes, setQRCodes] = useQRCodes();
  const [error, setError] = useState('');
  const isDevSite = Util.isDevSite();

  // Generate encoded properties for the dynamic link.  This also acts as a lookup for qrcodes.
  const extraSettings = Object.fromEntries(Object.entries(settings).filter(([, value]) => value.trim() !== ''));
  // Use fast-json-json-stringify to guarantee deterministic behavior
  const args = {
    mobileID: `${isDevSite ? 't.' : ''}${regattaId}`,
    mobilePin,
    stationType,
    ...extraSettings,
  };
  const jsonArgs = stringify(args);
  const qrProps = Buffer.from(jsonArgs, 'utf8').toString('base64');

  // Determine if we already have a cached qrcode with the same props
  let qrcode: QRCodeInfo | undefined;
  for (const code of Object.values(qrCodes)) {
    if (code.props === qrProps) {
      qrcode = code;
      break;
    }
  }

  if (stationType === 'Results') {
    qrcode = {
      mobilePin: '',
      name: '',
      url: `https://${isDevSite ? 'dev.' : ''}crewtimer.com/regatta/${regattaId}`,
      uuid: '',
      props: '',
    };
  }

  const initiatePrint = () => {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  const generateQRCode = () => {
    const link = `https://crewtimer.com/goto/${regattaId}/${qrProps}`;
    const apiKey = 'AIzaSyBxl61gy473Yq7KDT_838HYPnRsfZz_Y5M';
    const queryUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;
    setError('');
    const options = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://link.crewtimer.com/m',
        link,
        androidInfo: {
          androidPackageName: 'net.entazza.crewtimer',
        },
        iosInfo: {
          iosBundleId: 'net.entazza.crewtimer',
          iosAppStoreId: '1450216565',
        },
      },
      suffix: {
        option: 'UNGUESSABLE',
      },
    };
    fetch(queryUrl, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const shortLink = data.shortLink;
        const uuid = uuidv1();
        const newRecord: QRCodeInfo = {
          uuid,
          mobilePin,
          name: stationType,
          url: shortLink,
          props: qrProps,
        };
        const newCodes = { ...qrCodes, [uuid]: newRecord };
        setQRCodes(newCodes);
        Util.setQRCodeInfo(newCodes, regattaId)
          .then()
          .catch((e) => setError(`Error: ${String(e)}`));
      })
      .catch((e) => setError(`Error: ${String(e)}`));
  };
  return (
    <CardWrapper title={title}>
      <Box sx={{ gridColumnStart: 'span 2' }}>
        <Stack>
          <Typography sx={{ fontSize: 'h6.fontSize' }}>{`${
            stationType === 'Results' ? 'Results at crewtimer.com' : `Station Type: ${stationType}`
          }`}</Typography>
          {qrcode ? (
            <Stack>
              <div>
                <QRCode size={150} value={qrcode.url} style={{ marginTop: '1em' }} />
              </div>
              <Link href={qrcode.url} className="noprint">
                Shareable Link
              </Link>
            </Stack>
          ) : (
            <>
              <Button onClick={generateQRCode}>Generate QRCode</Button>
            </>
          )}
          {accessLevel && <Typography sx={{ marginTop: '1em' }}>{`Access Level: ${accessLevel}`}</Typography>}
          {Object.entries(extraSettings).map((entry) => (
            <Typography key={entry[0]}>{`${entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}: ${
              entry[1]
            }`}</Typography>
          ))}
          {error && (
            <Typography color="#ff0000" sx={{ marginTop: '1em' }}>
              {error}
            </Typography>
          )}
          {stationType !== 'Results' && (
            <>
              <Typography sx={{ marginTop: '1em' }}>For event timing staff only.</Typography>
              <Typography>Do not share.</Typography>
            </>
          )}
          {stationType === 'Results' && (
            <Typography sx={{ fontStyle: 'italic', marginTop: '1em' }}>Timing Simplified!</Typography>
          )}
          <IconButton className={`noprint`} color="inherit" aria-label="Print" onClick={initiatePrint} size="large">
            <PrintIcon />
          </IconButton>
        </Stack>
      </Box>
    </CardWrapper>
  );
};

const QRCodes = () => {
  const [regattaConfig] = useRegattaConfig();
  const [regattaInfo] = useRegattaInfo();
  const [selectedRegatta] = useSelectedRegatta();
  const [regattaTitle] = useRegattaTitle();
  const [waypoints] = useTimingWaypoints();
  const mobileKey = regattaConfig?.MobileKey || '';
  const [activeCode, setActiveCode] = useState(`${mobileKey}-Timing-Admin`);
  const [waypoint, setWaypoint] = useState('Start');
  const [day, setDay] = useState('None');
  const [trackingStations] = useTrackingStations();
  const [trackingStation, setTrackingStation] = useState(trackingStations.length ? trackingStations[0].name : '');
  const dayList: string[] = regattaInfo?.DayList || [];
  const trackingSelected = trackingStations.find((station) => activeCode.includes(`-${station.name}-`));

  const handleChange = (event: SelectChangeEvent) => {
    setActiveCode(event.target.value);
  };

  const handleWPChange = (event: SelectChangeEvent) => {
    setWaypoint(event.target.value);
  };

  const handleDayChange = (event: SelectChangeEvent) => {
    setDay(event.target.value);
  };
  const handleTrackingChange = (event: SelectChangeEvent) => {
    setTrackingStation(event.target.value);
  };

  let qrcodeSelects = [
    [`${mobileKey}-Results`, `Results Website`],
    [`${mobileKey}-Timing-Admin`, `Mobile Config - Master Pin (${mobileKey}) - Timing`],
    [`${mobileKey}-Timing+Penalties-Admin`, `Mobile Config - Master Pin (${mobileKey}) - Timing+Penalties`],
    [`${mobileKey}-Penalties-Admin`, `Mobile Config - Master Pin (${mobileKey}) - Penalties`],
  ];
  const wpSelects = ['Start', ...waypoints, 'Finish'].map((wp) => [wp, wp]);
  const daySelects = ['None', ...dayList].map((d) => [d, d]);
  const trackingSelects = trackingStations.map((c) => [c.name, c.name]);

  if (trackingStations.length) {
    qrcodeSelects = [[`${mobileKey}-Tracking`, `Tracking Station`], ...qrcodeSelects];
  }

  // Add user pins, if any
  (regattaConfig?.MobilePins || []).forEach((pinInfo) =>
    qrcodeSelects.push([
      `${pinInfo.pin}-${pinInfo.stationType}-Limited`,
      `Mobile Config - Preset Pin (${pinInfo.pin}) - ${pinInfo.stationType}`,
    ])
  );

  const [mobilePin, stationTypeField, accessLevel] = activeCode.split('-');
  let stationType = stationTypeField;
  if (!selectedRegatta || !regattaConfig) {
    return <LoadingIndicator />;
  }

  const extras: KeyMap = {};
  let disableWaypoint = false;
  let disableDay = false;
  let disableTracking = true;
  switch (stationType) {
    case 'Results':
      disableWaypoint = true;
      disableDay = true;
      disableTracking = true;
      break;
    case 'Tracking':
      disableWaypoint = true;
      disableTracking = false;
      break;
    default: {
    }
  }
  if (trackingSelected) {
    disableWaypoint = true;
  }
  if (!disableDay && day !== 'None') {
    extras.day = day;
  }
  if (!disableWaypoint) {
    extras.waypoint = waypoint;
  }
  if (!disableTracking) {
    stationType = trackingStation;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '2em' }}>
      <Stack>
        <Alert severity="warning" className="noprint" sx={{ marginBottom: '1em', width: 300 }}>
          Version 5.7.3 of the mobile app is required to utilize QR codes. In most cases, the camera app can be used to
          read QR codes. Click the printer icon for a printable version.
        </Alert>
        <FormControl fullWidth sx={{ marginBottom: '1em' }} className="noprint">
          <InputLabel id="qrcode-select-label">Preset</InputLabel>
          <Select
            labelId="qrcode-select-label"
            id="qrcode-select"
            value={activeCode}
            label="QR Code"
            onChange={handleChange}
          >
            {qrcodeSelects.map((code) => (
              <MenuItem key={code[0]} value={code[0]}>
                {code[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: '1em' }} className="noprint">
          <InputLabel id="wp-select-label">Waypoint</InputLabel>
          <Select
            disabled={disableWaypoint}
            labelId="wp-select-label"
            id="wp-select"
            value={waypoint}
            label="Waypoint"
            onChange={handleWPChange}
          >
            {wpSelects.map((code) => (
              <MenuItem key={code[0]} value={code[0]}>
                {code[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {trackingStations.length > 0 && (
          <FormControl fullWidth sx={{ marginBottom: '1em' }} className="noprint">
            <InputLabel id="trk-select-label">Tracking Station</InputLabel>
            <Select
              disabled={disableTracking}
              labelId="trk-select-label"
              id="trk-select"
              value={trackingStation}
              label="Tracking Station"
              onChange={handleTrackingChange}
            >
              {trackingSelects.map((code) => (
                <MenuItem key={code[0]} value={code[0]}>
                  {code[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {dayList.length > 0 && (
          <FormControl fullWidth sx={{ marginBottom: '1em' }} className="noprint">
            <InputLabel id="day-select-label">Day</InputLabel>
            <Select
              disabled={disableDay}
              labelId="day-select-label"
              id="day-select"
              value={day}
              label="Day"
              onChange={handleDayChange}
            >
              {daySelects.map((code) => (
                <MenuItem key={code[0]} value={code[0]}>
                  {code[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <MobileLinkCard
          title={regattaTitle}
          mobilePin={mobilePin}
          stationType={stationType}
          accessLevel={accessLevel}
          settings={extras}
        />
      </Stack>
    </Box>
  );
};

export default QRCodes;
