import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { UseDatum } from 'react-usedatum';
import { setDialogConfig } from './ConfirmDialog';
import { Lap } from './CrewTimerTypes';
import { useSelectedRegatta } from './RegattaState';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Util from './Util';
import { Entry } from './Entry';
import { v1 as uuidv1 } from 'uuid';

interface AnchorContext {
  ts: string;
  wp: string;
  row: Entry;
  anchor: any; // ((props: Element) => Element) | null;
  open: boolean;
}
export const [useAnchorContext, setContextAnchor] = UseDatum<AnchorContext | undefined>(undefined);
let copyContext: AnchorContext | undefined;
export const TimeContextMenu = () => {
  const [context, setContext] = useAnchorContext();
  const [regatta] = useSelectedRegatta();

  if (!context || (!context.ts && !copyContext?.ts)) {
    return <></>;
  }
  const handleClose = () => {
    setContext({ ...context, open: false });
  };
  const menuClick = (name: string) => () => {
    setContext({ ...context, open: false });
    switch (name) {
      case 'Copy':
        copyContext = context;
        break;
      case 'Paste':
        // console.log(`Update Event ${context.row.EventNum}-${context.row.Bow} ${context.wp} with ${copyContext?.ts}`);
        setDialogConfig({
          handleConfirm: () => {
            if (!context || !copyContext) {
              return;
            }
            const lap: Lap = {
              keyid: '',
              uuid: uuidv1(),
              AdjTime: '',
              SequenceNum: 1,
              Bow: context.row.Bow,
              EventNum: context.row.EventNum,
              Crew: '',
              CrewAbbrev: '',
              Event: '',
              EventAbbrev: '',
              Time: copyContext.ts,
              Gate: context.wp === 'S' ? 'S' : context.wp === 'F' ? 'F' : `G_${context.wp}`,
              Place: 0,
              Stroke: '',
            };
            // console.log(`lap=${JSON.stringify(lap, null, 2)}`);
            Util.storeLap(regatta, lap).catch(() => {});
          },
          title: `Replace timestamp`,
          message: `Update Event ${context.row.EventNum}-${context.row.Bow} ${context.wp} ${context?.ts || ''} with ${
            copyContext?.ts
          }`,
          button: 'Replace',
          showCancel: true,
        });
        break;
      default:
        break;
    }
  };
  return (
    <Menu id="render-props-menu" anchorEl={context?.anchor} open={!!context?.open} onClose={handleClose}>
      {context?.ts && (
        <MenuItem key="copy" onClick={menuClick('Copy')}>
          <ListItemIcon>
            <ContentCopyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{`Copy ${context?.ts || ''}`}</Typography>
        </MenuItem>
      )}
      {copyContext?.ts && (
        <MenuItem key="paste" onClick={menuClick('Paste')}>
          <ListItemIcon>
            <ContentPasteGoIcon />
          </ListItemIcon>
          <Typography variant="inherit">{`Paste ${copyContext?.ts || ''}`}</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};
