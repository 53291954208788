import { Results } from './CrewTimerTypes';
import KeyMap from './KeyMap';
import * as Names from './Names';
import { milliToString, timeToMilli } from './TimeUtil';

/**
 * Prepare results for display or export.
 * Results are stored in 'declaration' order in the database to minimize
 * update triggers that would occur as finish times come in and change order of
 * entries.  As a result, the final presentation for display or csv export needs
 * to order in 'finish' order which has already been computed and stored in
 * the event as Names.N_EVENT_DISPLAY_ORDER.
 *
 * @param eventList The event list stored in results
 */
export function prepareResultsForDisplay(results: Results, forceFinishSort: boolean) {
  let eventList: KeyMap[] = results[Names.N_RESULTS];
  const regattaInfo = results[Names.N_REGATTA_INFO] || {};
  const showPlacement = regattaInfo[Names.N_FINISHED] || !regattaInfo[Names.N_RESULTS_PEND_OFFICIAL];
  const resultDigits = Number(regattaInfo[Names.N_RESULT_DIGITS] || 3);
  if (eventList === undefined) {
    eventList = [];
  }
  eventList.forEach((event) => {
    const displayOrder: number[] = event[Names.N_EVENT_DISPLAY_ORDER];
    const official = Boolean(event[Names.N_OFFICIAL]);
    let entryList: KeyMap[] = event[Names.N_ENTRIES];

    if (entryList === undefined) {
      entryList = []; // can happen if spreadsheet drops an event which already has a time
    }
    // Add in a key that is establishes DB order before we sort so adjust results can use it
    entryList.forEach((entry, i) => (entry[Names.N_INDEX] = i));
    if (displayOrder && (forceFinishSort || showPlacement || official)) {
      // sort entry list by display order
      entryList = displayOrder.map((index) => entryList[index]);
      event[Names.N_ENTRIES] = entryList;
    }
    // Compute delta between entries
    let winnerTimeMs = 0;
    entryList.forEach((entry) => {
      const finalTime = entry[Names.N_ADJ_TIME];
      if (!finalTime) {
        return;
      }
      const finalTimeMs = timeToMilli(finalTime);
      if (winnerTimeMs === 0) {
        winnerTimeMs = finalTimeMs;
      }
      const deltaMs = finalTimeMs - winnerTimeMs;

      // don't include a delta time for the winner
      if (deltaMs > 0) {
        const delta = milliToString(deltaMs, false, resultDigits);
        entry[Names.N_SPLIT] = delta;
      }
    });
  });
  return results;
}

const EventKeys = ['EventNum', 'Event'];
const ColKeys1 = ['Place', 'Crew', 'CrewAbbrev', 'Bow', 'Stroke'];
const ColKeys2 = ['RawTime', 'PenaltyCode', 'AdjTime', 'Delta'];
export function getExportResults(results: Results, format?: 'csv' | 'json') {
  const waypoints = results.regattaInfo.ResultWaypoints ? results.regattaInfo.ResultWaypoints.split(',') : [];
  const cookedResults = prepareResultsForDisplay(results, true);
  const colNames = [...ColKeys1, 'Start', 'Finish', ...waypoints, ...ColKeys2];
  const colKeys = [...ColKeys1, 'S_time', 'G_Finish_time_raw', ...waypoints.map((wp) => `G_${wp}_time`), ...ColKeys2];

  if (format === 'csv' || format === undefined) {
    // Build up a matrix representing a spreadsheet
    const values = cookedResults.results.map((row: KeyMap) => {
      const entries: KeyMap[] = row.entries;
      if (!entries) {
        return [];
      }
      return entries.map((entry: KeyMap) => [...EventKeys, ...colKeys].map((k) => String(entry[k] || '')));
    });
    // convert to CSV
    let csv =
      [...EventKeys, ...colNames].map((item) => (item ? `"${String(item).replace(/"/g, '""')}"` : '""')).join(',') +
      '\n';
    csv += values
      .map((evt) => {
        const entries = evt.map((entry) =>
          entry.map((item) => (item ? `"${String(item).replace(/"/g, '""')}"` : '""'))
        );
        return entries.join('\n');
      })
      .join('\n');
    return csv;
  } else {
    const eventList: KeyMap[] = [];
    cookedResults.results.forEach((evt: KeyMap) => {
      const eventInfo: KeyMap = {};
      ['EventNum', 'Event', 'EventInfo', 'RaceType', 'Start'].forEach(
        (key) => (eventInfo[key] = String(evt[key] || ''))
      );
      eventInfo.entries = evt?.entries?.map((entry: KeyMap) => {
        const entryInfo: KeyMap = {};
        colNames.forEach((name, i) => {
          entryInfo[name] = entry[colKeys[i]] || '';
        });
        return entryInfo;
      });
      // return entries.map((entry: KeyMap) => colKeys.map((k) => entry[k] || ''));
      eventList.push(eventInfo);
    });
    return eventList;
  }
}
