import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { ChangeContext, getField, useStyles } from "./FormGlobals";

export const SelectFormField = ({
    name,
    label,
    value,
    selects,
    info,
    context,
}: {
    name: string;
    value?: string;
    label?: string;
    info?: string;
    selects: string[][];
    context: ChangeContext;
}) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl} margin="dense">
            <InputLabel className={classes.textlabel}>{label || name}</InputLabel>
            <Select
                variant="standard"
                value={value !== undefined ? value : getField(context, name)}
                name={name}
                onChange={context.onSelectChange}
            >
                {selects.map((item) => (
                    <MenuItem key={item[0]} value={item[1]}>
                        {item[0]}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText className={classes.formHelperText}>{info || ''}</FormHelperText>
        </FormControl>
    );
};