import React, { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormHelperText } from '@mui/material';

interface CellEditorProps {
  name?: string;
  title?: string;
  prompt?: string;
  editable?: boolean;
  editValue?: string;

  regex?: string;
  defaultValue?: string;
  onClick?: () => void;
  onChange?: (name: string | undefined, newValue: string) => void;
}
const CellEditor: FC<React.PropsWithChildren<CellEditorProps>> = ({
  name,
  title,
  prompt,
  editable,
  editValue,
  regex,
  defaultValue,
  onClick,
  onChange,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [tvalue, setTvalue] = useState('');
  const [newValue, setNewValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const validate = new RegExp(regex ? regex : '.*');

  useEffect(() => {
    setTvalue(editValue ? editValue : defaultValue || '');
  }, [editValue, defaultValue]);

  const handleOpen = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (onChange && newValue) {
      onChange(name, newValue);
    }

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onTextChange = (event) => {
    const ok = validate.test(event.target.value);
    setNewValue(event.target.value);
    setTvalue(event.target.value);
    setErrorText(ok ? '' : 'Invalid value');
  };

  const actions = [
    <Button key={0} variant="contained" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key={1} variant="contained" disabled={errorText ? true : false} onClick={handleClose}>
      OK
    </Button>,
  ];

  const val = defaultValue ? defaultValue : <div>&nbsp;</div>;
  if (editable)
    return (
      <div style={{ border: '1px solid rgb(100, 181, 246)' }}>
        {/* {this.state.errorText && <Typography className={classes.error} variant='subtitle1'>{this.state.errorText}</Typography>} */}
        <div onClick={handleOpen}>{val}</div>
        <Dialog
          title={title ? title : prompt}
          open={open || open || false}
          onClose={handleClose}
          style={{ maxWidth: 400, zIndex: 5000 }}
        >
          <DialogTitle id="alert-dialog-title">{title ? title : prompt}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{}</DialogContentText>
            <TextField
              id="cell-textfield"
              variant="standard"
              style={{ width: 250 }}
              error={Boolean(errorText)}
              value={tvalue}
              onChange={onTextChange}
            />
            <FormHelperText>{prompt}</FormHelperText>
            {children}
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    );
  return <div>{defaultValue}</div>;
};

export default CellEditor;
