import { Snackbar, Alert } from '@mui/material';
import { UseDatum } from 'react-usedatum';

export const [useToast] = UseDatum('');
export const Toast = () => {
  const [toast, setToast] = useToast();

  if (toast) {
    console.log(toast);
  }

  return (
    <Snackbar
      open={toast !== ''}
      autoHideDuration={4000}
      onClose={() => {
        setToast('');
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={toast.startsWith('Err') ? 'error' : 'success'}>{toast}</Alert>
    </Snackbar>
  );
};
