/**
 * Created by glenne on 6/10/2017.
 */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Measure from 'react-measure';
const styles: { [key: string]: React.CSSProperties } = {
  abs: {
    position: 'fixed',
    display: 'inline-block', // only as big as needed
    top: '0px',
    left: '-1000px', // offscreen
  },
};

interface MeasureProps {
  text: string | string[];
  onMeasure: (dims: { [key: string]: any }) => void;
  textClass?: string;
  textStyle?: any;
}

// Measure text specified by 'text' property and return a map
// of results via an onMeasure callback.  The style of the text
// can be specified via the textStyle property.
const MeasureText: FunctionComponent<MeasureProps> = (props) => {
  const [, setDims] = useState<{ [key: string]: any }>({});
  const [text, setText] = useState<string[]>([]);

  useEffect(() => {
    setText(Array.isArray(props.text) ? props.text : [props.text]);
  }, [props.text]);

  const recordBounds = (txt, bounds) => {
    setDims((prior) => {
      const next = { ...prior, [txt]: bounds };
      if (Object.keys(next).length === text.length) {
        if (props.onMeasure) {
          props.onMeasure(next);
        }
      }
      return next;
    });
  };
  return (
    <div style={styles.abs}>
      {/* Emit a Measure component for each text item  Place the result into this.dims. */}
      {text.map((txt) => (
        <Measure
          key={txt}
          bounds
          onResize={(contentRect) => {
            contentRect.bounds.width = Math.trunc(Number(contentRect.bounds.width) + 0.999);
            recordBounds(txt, contentRect.bounds);
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} style={Object.assign({}, styles.abs, props.textStyle)} className={props.textClass}>
              {txt}
            </div>
          )}
        </Measure>
      ))}
    </div>
  );
};

export default MeasureText;
