import { KeyMap } from "crewtimer-common";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '56px',
        minHeight: '56px',
        color: '#000000',
        backgroundColor: '#e8e8e8',
    },

    toolbarOption: {
        marginTop: theme.spacing(1),
        height: '40px',
        minHeight: '40px',
        color: '#000000',
        backgroundColor: '#e8e8e8',
    },
    grow: {
        flexGrow: 1,
    },
    textlabel: {
        whiteSpace: 'nowrap',
    },
    formFieldTitle: {
        whiteSpace: 'nowrap',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
    },
    checkbox: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    error: {
        color: 'red',
    },
    formHelperText: {
        maxWidth: 260,
    },
    container: {},
    textField: {},
    testing: {},
    testPass: { paddingLeft: theme.spacing(1), backgroundColor: 'green', color: 'white' },
    testFail: { paddingLeft: theme.spacing(1), backgroundColor: 'red', color: 'white' },
}));

/** Extract a field from context by name.
 *
 * Name can be multi level such as mobilePins/0/pin to reference mobilePins[0].pin
 *
 * @param context The source of data
 * @param name The name to extract
 */
export function getField(context: ChangeContext, name: string, def: string = '') {
    let value = context.properties[name];

    if (value === undefined && name.includes('/')) {
        value = context.properties;
        const keys = name.split('/');
        keys.forEach((key) => (value = value?.[key]));
    }
    return String(value === undefined ? def : value || '');
}
export interface ChangeContext {
    properties: KeyMap;
    errors: KeyMap<string>;
    onChange: any;
    onCheckChange: any;
    onSelectChange: any;
    onMultiCheckChange: any;
}