export function get3digitString(i: number | string) {
  let s = String(i);
  while (s.length < 3) {
    s = '0' + s;
  }
  return s;
}
export function get2digitString(i: number | string) {
  let s = String(i);
  if (s.length < 2) {
    s = '0' + s;
  }
  return s;
}

/**
 * Convert a time formatted as [[HH:]MM:]SS.MMM] to milliseconds.
 *
 * The algorithm starts from the right and multiplies the first set of
 * numbers by 1000, the 2nd set from right by 60000 and the the third by
 * 3600000. Any of the fields can be missing on the left.
 *
 * @param time
 * @return
 */
export function timeToMilli(time: string) {
  if (!time) {
    return 0;
  }
  let sign = 1;
  time = time.trim();
  if (time.startsWith('-')) {
    sign = -1;
    time = time.substring(1);
  }
  if (time === null || time.length === 0) {
    return 0;
  }
  const times = time.split(':');
  let factor = 1000;
  let v = 0.0;
  for (let i = times.length - 1; i >= 0; i--) {
    try {
      let num = Number.parseFloat(times[i]);
      if (Number.isNaN(num)) {
        num = 0;
      }
      v = v + factor * num;
    } catch (e) {
      console.log("Bad time format: '" + time + "'");
    }
    factor = factor * 60;
  }
  v = v + 0.01; // round before trunc
  return Math.trunc(v * sign);
}

/**
 * Convert a timestamp in milliseconds to a string.
 *
 * @param {int} elapsed Time in milliseconds
 * @param {boolean} includeHours true to always include hours in the result, possibly 00:
 * @param {int} milliDigits Number of digits after decimal
 */
export function milliToString(elapsed: number, includeHours: boolean = true, milliDigits: number = 3) {
  const negative = elapsed < 0;
  if (negative) {
    elapsed = -elapsed;
  }
  if (milliDigits === 2) {
    elapsed = elapsed + 5;
  } else if (milliDigits === 1) {
    elapsed = elapsed + 50;
  }
  const h = Math.trunc(elapsed / (60000 * 60));
  elapsed = elapsed - 60000 * 60 * h;
  const m = Math.trunc(elapsed / 60000);
  elapsed = elapsed - 60000 * m;
  const s = Math.trunc(elapsed / 1000);
  elapsed = elapsed - 1000 * s;
  const frac = elapsed;

  // when showing hours, discard decimals
  if (h > 0) {
    includeHours = true;
  }

  // Otter Island has times greater than 60 minutes
  let result = get2digitString(m) + ':' + get2digitString(s) + '.' + get3digitString(frac);
  if (includeHours) {
    result = get2digitString(h) + ':' + result;
  }
  if (negative) {
    result = '-' + result;
  }
  if (milliDigits >= 3) {
    return result;
  }

  if (milliDigits <= 0) {
    result = result.substring(0, result.length - 4);
  } else {
    result = result.substring(0, result.length - 3 + milliDigits);
  }
  return result;
}

/**
 * Subtract time1 from time2 and format as a string.
 *
 * @param time1 String form of time HH:MM:SS.SSS
 * @param time2 String form of time HH:MM:SS.SSS
 * @param includeHours
 */
export function timeDiff(time1: string | undefined, time2: string | undefined, includeHours = true) {
  if (!time1 || !time2) return '';
  const t1 = timeToMilli(time1);
  const t2 = timeToMilli(time2);
  const delta = t2 - t1;
  const s = milliToString(delta, includeHours);
  return s;
}

export function timeAdd(time1: string | undefined, time2: string | undefined, includeHours = true) {
  if (!time1 || !time2) return time1;
  const t1 = timeToMilli(time1);
  const t2 = timeToMilli(time2);
  const delta = t2 + t1;
  const s = milliToString(delta, includeHours);
  return s;
}
