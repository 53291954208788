import React from 'react';
import Iframe from 'react-iframe';

const TermsOfUse = () => {
  const url =
    'https://docs.google.com/document/d/e/2PACX-1vQFVjDUKOJeBsfHWs6EqG_GuJ4OU8xKHEiJRQBm5PTsoO5vCqF1CKgg_DOujtON3WGqif3apKWkM6YM/pub?embedded=true';
  return (
    <div style={{ margin: '10px' }}>
      <Iframe width="100%" height="800" url={url} />
    </div>
  );
};

export default TermsOfUse;
