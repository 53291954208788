import { useEffect } from 'react';
import { UseDatum } from 'react-usedatum';
import { useKiosk } from './UseKiosk';
import { useRegattaInfo, useLastUpdatedEvent } from './UseResults';

export const [useKioskEvent] = UseDatum('');
export const [useResultsPending] = UseDatum(false);
export const [useNumEvents] = UseDatum(-1);

/**
 * Watch various settings for changes and fire off react updates on change.  This
 * shim is used to take higher level settings and boil them down to just the ones
 * needed for triggering updates to the results page.
 *
 * While it is a react component, it renders an empty component.
 *
 * Note that the setters are based on UseDatum and will do deep compare and will not
 * fire a change unless a change actually occurs.
 *
 * @returns An empty component.
 */
const ChangeTracker = () => {
  const [kioskMode] = useKiosk();
  const [regattaInfo] = useRegattaInfo();
  const [lastUpdatedEvent] = useLastUpdatedEvent();
  const [, setKioskEvent] = useKioskEvent();
  const [, setResultsPending] = useResultsPending();
  const [, setNumEvents] = useNumEvents();

  useEffect(() => {
    if (kioskMode && !regattaInfo?.Finished && lastUpdatedEvent) {
      setKioskEvent(lastUpdatedEvent);
    } else {
      setKioskEvent('');
    }
    setResultsPending(regattaInfo?.ResultsPending || false);
    setNumEvents(regattaInfo?.NumEvents || -1);
  }, [kioskMode, lastUpdatedEvent, regattaInfo, setKioskEvent, setNumEvents, setResultsPending]);
  return <></>;
};

export default ChangeTracker;
