import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Toolbar from '@mui/material/Toolbar';
import Util from '../shared/Util';
import * as Names from '../shared/Names';
import CellEditor from './CellEditor';
import StopwatchOptions from './StopwatchOptions';
import { milliToString, timeToMilli, timeDiff, timeAdd } from '../shared/TimeUtil';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { Lap } from '../shared/CrewTimerTypes';
import { useSelectedRegatta, useStopwatchConfig, useTimingWaypoints } from '../shared/RegattaState';
import { niceWaypoint } from '../shared/StringUtil';
import KeyMap from '../shared/KeyMap';
import { Link } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import { UseDatum } from 'react-usedatum';
import { Popover, PopoverState } from 'react-tiny-popover';
const uuidv1 = require('uuid/v1');

export interface LapEditorInfo {
  entryInfo: Lap;
  anchor: any;
}
export const [useLapEditorInfo, setLapEditorInfo] = UseDatum<LapEditorInfo | undefined>(undefined);

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: 'white', margin: 5 },
  inner: {
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: 'green',
    padding: 5,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  toolbar: {
    height: '48px',
    minHeight: '48px',
    color: '#000000',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    backgroundColor: '#e8e8e8',
  },
  toolbarBottom: {
    height: '48px',
    minHeight: '48px',
    color: '#000000',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },

  textlabel: {
    whiteSpace: 'nowrap',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControl: {
    marginLeft: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  formHelperText: {
    maxWidth: 260,
  },
  table: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  selectItem: {},
}));

interface LapEditorProps {
  onRequestClose: () => void;
}

const LapEditor: FC<LapEditorProps> = ({ onRequestClose }) => {
  const classes = useStyles();
  const [modifiedItems, setModifiedItems] = useState<{ [key: string]: boolean }>({});
  const [lapItems, setLapItems] = useState<{ [uuid: string]: Lap }>({});
  const [tempItems, setTempItems] = useState({});
  const [showUnassigned, setShowUnassigned] = useState(false);
  const [showUnassignedDeleted, setShowUnassignedDeleted] = useState(false);
  const [timingWaypoints] = useTimingWaypoints();
  const [stopwatchConfig] = useStopwatchConfig();
  const [lapEditorInfo] = useLapEditorInfo();
  const [regatta] = useSelectedRegatta();
  const defaultValue = lapEditorInfo?.entryInfo;

  const eventId = defaultValue?.EventNum || '';
  const bow = defaultValue?.Bow || '';
  const entryId = `1-${eventId}-${bow}`;

  const rxFirebaseDataOnce = useMemo(
    () => (laps: KeyMap<Lap>) => {
      setLapItems((cur) => {
        return { ...cur, ...laps };
      });
    },
    []
  );

  useEffect(() => {
    // search by event and filter by bow in rxFirebaseData
    Util.getLapDataOnce(regatta, eventId, bow, rxFirebaseDataOnce);
    Util.getLapDataOnce(regatta, eventId, '?', rxFirebaseDataOnce);
    Util.getLapDataOnce(regatta, eventId, '*', rxFirebaseDataOnce);
  }, [regatta, eventId, bow, rxFirebaseDataOnce]);

  useEffect(() => {
    if (!showUnassigned) {
      return;
    }
    Util.getLapDataOnce(regatta, '', '?', rxFirebaseDataOnce);
  }, [showUnassigned, regatta, rxFirebaseDataOnce]);

  const emptyEvent = useMemo(() => {
    return (gate) => {
      const event = {};
      const uuid = uuidv1();
      event[Names.N_UUID] = uuid;
      event[Names.N_REGATTA] = regatta;
      event[Names.N_GATE] = gate;
      event[Names.N_EVENTNUM] = defaultValue?.EventNum || '';
      event[Names.N_BOW] = defaultValue?.Bow || '';
      event[Names.N_CREW] = defaultValue?.Crew || '';
      if (gate === 'R') {
        event[Names.N_PENALTY_CODES] = Names.N_OFFICIAL;
        event[Names.N_STATE] = Names.STATE_DELETED;
      }
      event[Names.N_EVENT_ID] = eventId;
      event[Names.N_ENTRY_ID] = entryId;

      if (gate === 'Pen' || gate === 'R') {
        const d = new Date();
        let milli = d.getHours() * 3600 * 1000;
        milli += d.getMinutes() * 60 * 1000;
        milli += d.getSeconds() * 1000;
        milli += d.getMilliseconds();
        event[Names.N_TIME] = milliToString(milli);
      }
      return event as Lap;
    };
  }, [regatta, defaultValue, eventId, entryId]);

  const [timingPoints, lapdata] = useMemo(() => {
    const _timingPoints = timingWaypoints.map((gate) => 'G_' + gate);
    const waypoints = ['S', ..._timingPoints, 'F', 'Pen', 'R'];
    const temp = {};

    const displayLaps = { ...lapItems };

    // Inject dummy waypoint entries to show in list if none present.
    const missingWaypoints: KeyMap = {};
    waypoints.forEach(function (element) {
      missingWaypoints[element] = true;
    });
    missingWaypoints.R = false;
    missingWaypoints.Pen = false;
    Object.values(displayLaps).forEach(function (entry) {
      const gate = entry.Gate === '*' ? 'S' : entry.Gate || 'S';
      if (entry[Names.N_BOW] === '?') return;
      missingWaypoints[gate] = false;
    });

    // Add in 'missing' waypoints
    for (const key in missingWaypoints) {
      if (missingWaypoints[key]) {
        const newItem = emptyEvent(key);
        temp[newItem.uuid] = newItem;
        displayLaps[newItem[Names.N_UUID]] = newItem;
      }
    }

    // Sort by gate followed by time
    const laplist = Object.values(displayLaps).sort(function (a, b) {
      const agate = waypoints.indexOf(a.Gate || 'S');
      const bgate = waypoints.indexOf(b.Gate || 'S');
      if (agate < bgate) return -1;
      if (agate > bgate) return 1;
      if (a.Time === b.Time) return 0;
      if ((a.Time || '0') < (b.Time || '0')) return -1;
      return 1;
    });

    setTempItems(temp);
    return [_timingPoints, laplist];
  }, [lapItems, timingWaypoints, emptyEvent]);

  // Set startTime if present
  let startTime: string | undefined;
  const lapitems = Object.values(lapItems);
  lapitems.forEach(function (row) {
    const deleted = Names.STATE_DELETED === row[Names.N_STATE];
    if (deleted) {
      return;
    }

    const gate = row[Names.N_GATE];
    if (gate === 'S') {
      if (row[Names.N_OVERRIDE_WAYPPOINT]) {
        // An override is specified.  Look for it
        const replaceGate = row[Names.N_OVERRIDE_WAYPPOINT];
        const replacement = lapitems.find(
          (item) => Names.STATE_DELETED !== item[Names.N_STATE] && item[Names.N_GATE] === replaceGate
        );

        if (replacement) {
          row = replacement;
        }
      }
      if (row[Names.N_BOW] === '?') return;
      startTime = row[Names.N_TIME];
    }
  });

  const handleClose = () => {
    const displayItems = Object.assign({}, tempItems, lapItems);
    for (const uuid in modifiedItems) {
      const lap = displayItems[uuid];
      Util.storeLap(regatta, lap).catch((e) => {
        console.log(`Error storing lap: ${e.message ? e.message : String(e)}`);
      });
    }
    if (onRequestClose) {
      onRequestClose();
    }
  };
  const handleCancel = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const addPenaltyRow = () => {
    const newItem = emptyEvent('Pen');
    const uuid = newItem[Names.N_UUID];
    setLapItems(Object.assign({}, lapItems, { [uuid]: newItem }));
  };

  const stopwatchTimeFromTimestamp = (timestamp) => {
    const t1 = timeToMilli(stopwatchConfig.clockTime);
    const t2 = timeToMilli(stopwatchConfig.stopwatchTime);
    const t3 = timeToMilli(timestamp);
    const value = milliToString(t3 - t1 + t2);
    return value;
  };

  const onTimeChange = (name, value) => {
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign(modifiedItems);

    if (stopwatchConfig.useStopwatchTimes) {
      const t1 = timeToMilli(stopwatchConfig.clockTime);
      const t2 = timeToMilli(stopwatchConfig.stopwatchTime);
      const t3 = timeToMilli(value);
      value = milliToString(t1 - t2 + t3);
    } else {
      // Normalize to HH:MM::SS.sss
      value = milliToString(timeToMilli(value));
    }
    newItems[name][Names.N_TIME] = value;
    modItems[name] = true;
    // delete newItems[name];
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onElapsedChange = (name, value) => {
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign({}, modifiedItems);

    if (newItems[name][Names.N_GATE] === 'Pen') {
      newItems[name][Names.N_PENALTY_TIME] = value;
    } else {
      const newTime = timeAdd(startTime, value);
      newItems[name][Names.N_TIME] = newTime;
    }
    modItems[name] = true;
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onCodeChange = (name, value) => {
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign({}, modifiedItems);

    newItems[name][Names.N_PENALTY_CODES] = value;
    modItems[name] = true;
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onBowChange = (name, value) => {
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign({}, modifiedItems);
    const event = newItems[name];
    event[Names.N_BOW] = value;

    // prior bow might be '?', update fields
    event[Names.N_EVENTNUM] = defaultValue?.EventNum || '';
    event[Names.N_ENTRY_ID] = `1-${eventId}-${value}`;

    modItems[name] = true;
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onOverrideWaypointChange = (event) => {
    let value = event.target.value;
    const uuid = event.target.name;
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign({}, modifiedItems);
    if (value) {
      value = value.replace(/^Start$/, 'S').replace(/^Finish$/, 'F');
      if (value !== 'S' && value !== 'F') {
        value = `G_${value}`;
      }
      newItems[uuid][Names.N_OVERRIDE_WAYPPOINT] = value;
    } else {
      delete newItems[uuid][Names.N_OVERRIDE_WAYPPOINT];
    }
    modItems[uuid] = true;
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onDeletedChange = (event, value) => {
    const newItems = Object.assign({}, tempItems, lapItems);
    const modItems = Object.assign({}, modifiedItems);
    const name = event.target.name;
    newItems[name][Names.N_STATE] = value ? Names.STATE_DELETED : 'OK';
    modItems[name] = true;
    setLapItems(newItems);
    setModifiedItems(modItems);
  };

  const onUnassignedChange = (event, value) => {
    setShowUnassigned(value);
  };

  const onUnassignedDeletedChange = (event, value) => {
    setShowUnassignedDeleted(value);
  };

  const isModified = Object.keys(modifiedItems).length > 0;
  const actions = [
    <Button className={classes.button} variant="contained" size="small" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button className={classes.button} variant="contained" size="small" disabled={!isModified} onClick={handleClose}>
      Apply
    </Button>,
    <Button variant="contained" disabled={false} onClick={addPenaltyRow}>
      Add Penalty Row
    </Button>,
  ];

  const useStopwatchTimes = stopwatchConfig.useStopwatchTimes;
  const header = 'Event: ' + defaultValue?.EventNum + ' ' + defaultValue?.[Names.N_EVENT_NAME] + ', Bow ' + bow;
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.grow} variant="h6">
            {header}
          </Typography>
          <div>
            {actions[0]}
            {actions[1]}
          </div>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Waypoint</TableCell>
                <TableCell>
                  Use
                  <br />
                  Alternate
                </TableCell>
                <TableCell>Bow</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Delta Time</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Info</TableCell>
                <TableCell>Ignore Row</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lapdata.map((row) => {
                const deleted = Names.STATE_DELETED === row[Names.N_STATE];
                let gate = row[Names.N_GATE] || 'S';
                const bowValue = row[Names.N_BOW];
                if (!showUnassigned && bowValue === '?' && !modifiedItems[row.uuid]) return null;

                const editBow = 'R' !== gate;
                const editRef = 'R' === gate;
                const editPen = 'Pen' === gate;
                const reporter = row[Names.N_REPORTER];
                const location = row[Names.N_PENALTY_LOCATION];
                const comment = row[Names.N_PENALTY_COMMENT];
                const penInfo = [reporter, location, comment].join(' ');

                let code = '';
                const uuid = row[Names.N_UUID];
                const editElapsed = !(gate === 'S' || gate === '*' || editRef);
                let elapsed = !editElapsed ? '' : timeDiff(startTime, row[Names.N_TIME]);
                if (editPen) {
                  elapsed = row[Names.N_PENALTY_TIME] || '';
                  code = row[Names.N_PENALTY_CODES] || '';
                }
                if (editRef) {
                  code = row[Names.N_PENALTY_CODES] || '';
                }
                const editTime = !editPen && !editRef;
                elapsed = elapsed.replace(/^(00:)*/, '');
                if (bowValue === '?') elapsed = '';
                gate = niceWaypoint(gate);
                const timestamp = row[Names.N_TIME];
                let editTimestamp = '';
                if (editTime && timestamp && useStopwatchTimes) {
                  editTimestamp = stopwatchTimeFromTimestamp(timestamp);
                }
                const alternateWaypoints = timingPoints.filter((waypoint) => waypoint.match(/[0-9]/));
                const overrides = alternateWaypoints.filter(
                  (alternate) => !gate.match(/[0-9]/) && alternate.startsWith(`G_${gate}`)
                );
                if (
                  !showUnassignedDeleted &&
                  row.State === Names.STATE_DELETED &&
                  row.Bow === '?' &&
                  !modifiedItems[uuid]
                ) {
                  return null;
                }
                return (
                  <TableRow key={uuid}>
                    <TableCell>{gate}</TableCell>
                    <TableCell>
                      {overrides.length > 0 && (
                        <Select
                          variant="standard"
                          className="noprint"
                          displayEmpty
                          value={niceWaypoint(row[Names.N_OVERRIDE_WAYPPOINT])}
                          onChange={onOverrideWaypointChange}
                          style={{
                            fontSize: '1em',
                            marginLeft: '2%',
                            marginRight: '2%',
                            textOverflow: 'ellipsis',
                          }}
                          name={uuid}
                        >
                          <MenuItem key="none" value="" className={classes.selectItem}>
                            <em>None</em>
                          </MenuItem>
                          {overrides.map((waypoint) => {
                            waypoint = niceWaypoint(waypoint);
                            return (
                              <MenuItem key={waypoint} id={waypoint} value={waypoint} className={classes.selectItem}>
                                {`${waypoint}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>
                      <CellEditor
                        key={uuid}
                        name={uuid}
                        editable={!deleted && editBow}
                        defaultValue={bowValue}
                        title="Enter new Bow Number (* for Sprint Start)"
                        prompt=""
                        regex="^[0-9A-Za-z]+$|^\*$|^\?$"
                        onChange={onBowChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>{row[Names.N_EVENTNUM]}</Typography>
                    </TableCell>
                    <TableCell>
                      <CellEditor
                        name={uuid}
                        editable={!deleted && editTime}
                        editValue={editTimestamp}
                        defaultValue={row[Names.N_TIME]}
                        title="Enter time or stopwatch HH:MM:SS.SSS"
                        prompt="HH:MM:SS.SSS"
                        regex="^([0-9]??[0-9]:)??([0-9]??([0-9]:)??[0-9])??[0-9]([.][0-9]??[0-9]??[0-9]??)??$"
                        onChange={onTimeChange}
                      >
                        <StopwatchOptions />
                      </CellEditor>
                    </TableCell>
                    <TableCell>
                      <CellEditor
                        name={uuid}
                        editable={!deleted && editElapsed && Boolean(startTime)}
                        defaultValue={elapsed}
                        title="Enter delta time or relegation place"
                        prompt="MM:SS.SSS or Place"
                        regex="^([0-9]??[0-9]:)??([0-9]??([0-9]:)??[0-9])??[0-9]([.][0-9]??[0-9]??[0-9]??)??$"
                        onChange={onElapsedChange}
                      />
                    </TableCell>
                    <TableCell>
                      <CellEditor
                        name={uuid}
                        editable={!deleted && editPen}
                        defaultValue={code}
                        prompt="Enter new Code"
                        regex="^.+$"
                        onChange={onCodeChange}
                      />
                      {row.Docs?.[0] && (
                        <>
                          {row.Docs.map((pic) => (
                            <Link key={pic.uri} href={pic.uri} target="_blank" rel="noreferrer">
                              <PhotoIcon />
                            </Link>
                          ))}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>{penInfo}</Typography>
                    </TableCell>
                    <TableCell>
                      <FormControl className={classes.formControl} margin="dense">
                        <FormControlLabel
                          className={classes.textlabel}
                          label=""
                          control={
                            <Checkbox
                              className={classes.checkbox}
                              name={uuid}
                              checked={deleted}
                              onChange={onDeletedChange}
                              color="primary"
                            />
                          }
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Divider />
        <Toolbar className={classes.toolbarBottom}>
          <div>{actions[2]}</div>
          <div className={classes.grow}>
            <FormControl className={classes.formControl} margin="dense">
              <FormControlLabel
                className={classes.textlabel}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    name="showUnassigned"
                    onChange={onUnassignedChange}
                    color="primary"
                  />
                }
                label="Show unassigned timestamps"
              />
              <FormControlLabel
                className={classes.textlabel}
                control={
                  <Checkbox
                    disabled={!showUnassigned}
                    className={classes.checkbox}
                    name="showUnassignedDeleted"
                    onChange={onUnassignedDeletedChange}
                    color="primary"
                  />
                }
                label="Show unassigned deleted"
              />
            </FormControl>
          </div>
          <div>
            {actions[0]}
            {actions[1]}
          </div>
        </Toolbar>
      </div>
    </div>
  );
};

const LapEditorPopover = () => {
  const [lapEditorInfo] = useLapEditorInfo();
  const anchor = lapEditorInfo?.anchor;
  const open = !!lapEditorInfo;
  const openTime = useRef(0);

  useEffect(() => {
    openTime.current = Date.now();
  }, [open]);

  const handleRequestClose = () => {
    // Since our open click is outside the dummy content this is called when it opens.  Chedk
    // to see how long it has been open and ignore if less than certain time.
    const now = Date.now();
    // console.log('Popper close');
    if (open && openTime.current && now - openTime.current > 400) {
      setLapEditorInfo(undefined);
    }
  };

  return (
    <Popover
      parentElement={anchor}
      isOpen={open}
      padding={20}
      contentLocation={(popoverState: PopoverState) => {
        // console.log(JSON.stringify(popoverState));
        // If the popover would go above the screen, drop it below the click by 60, otherwise
        // place it above the click by it's height
        return {
          top: popoverState.childRect.y > -popoverState.popoverRect.height ? 60 : -popoverState.popoverRect.height,
          left: 0,
        };
      }}
      containerStyle={{ zIndex: '1' }} // to get above input text labels 'Start System' etc
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      // onClickOutside={handleRequestClose}
      content={<LapEditor onRequestClose={handleRequestClose} />}
    >
      <div />
      {/* <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>Click me!</div> */}
    </Popover>
  );
};
export default LapEditorPopover;
