/**
 * Created by glenne on 6/10/2017.
 */
import React, { FC } from 'react';
import * as Names from './Names';
import { ElapsedClock } from './ElapsedClock';
import { useEventResult } from './UseResults';

interface EventTableProps {
  eventNum: string;
}

const EventClock: FC<EventTableProps> = ({ eventNum }) => {
  const [event] = useEventResult(eventNum);
  if (event === undefined) {
    return <></>;
  }
  let { entries } = event;
  if (!entries) entries = [];
  const finished = event[Names.N_FINISHED];
  const official = event[Names.N_OFFICIAL];

  let start = '';
  let inProgress = false;
  let finish = '';
  // console.log(JSON.stringify(entries))
  for (const row of entries) {
    const startTimeString = row[Names.N_START_TIME];
    const finishTime = row.G_Finish_time_raw;
    start = start || startTimeString;
    const adj = row[Names.N_ADJ_TIME] || '';
    const skip = adj === 'DNS' || adj === 'DNF';
    if (skip) {
      continue;
    }
    finish = finish || finishTime;
    inProgress = inProgress || Boolean(!finishTime);
    // finish = finish || adj;
  }
  inProgress = inProgress && Boolean(start) && !official && !finished;

  return <ElapsedClock start={start} inProgress={inProgress} finish={finish} />;
};

export default EventClock;
