import React, { FC, useMemo } from 'react';
import Util from './Util';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCell } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Names from './Names';
import fire from '../fire';
import { useTranslation } from 'react-i18next';
import { useEventResult, useRegattaInfo } from './UseResults';
import { TrackingStation, KeyMap } from 'crewtimer-common';

Util.setFirebase(fire);

const evenColor = '#f0f0f0';
const oddColor = '#ffffff';

const styles: { [key: string]: React.CSSProperties } = {
  tableSuperHeader: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1.2em',
    fontWeight: 'bold',
    height: '40px',
    color: 'black',
    paddingLeft: '0.5em',
    backgroundColor: '#e0e0e0',
    textAlign: 'left',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableSuperHeaderRight: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    height: '40px',
    color: 'black',
    paddingLeft: '0.5em',
    backgroundColor: '#e0e0e0',
    textAlign: 'right',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableEventInfo: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    lineHeight: '1.2em',
    fontWeight: 'normal',
    color: 'black',
    paddingLeft: '1em',
    backgroundColor: '#e0e0e0',
    textAlign: 'left',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  TableHead: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    // height: "30px",
    color: 'black',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
  tableRow: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'normal',
    height: '30px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
  },
  headerRow: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    height: '30px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
    backgroundColor: '#f0f0f0',
  },
};

const TimeTemplateShort = '00:00:00';
const BowTemplate = '000';

const formatTime = (timeString: string) => {
  if (!timeString) {
    return '';
  }
  const dotIndex = timeString.indexOf('.');
  if (dotIndex !== -1) {
    return timeString.slice(0, dotIndex);
  } else {
    return timeString;
  }
};

interface EventTableProps {
  width: number;
  tableWidth: number;
  dimensions: KeyMap;
  regatta: string;
  eventNum: string;
  tableNumber: number;
}

const TrackingEventTable: FC<EventTableProps> = (props) => {
  const eventNum = props.eventNum;
  const { t } = useTranslation();
  const [regattaData] = useRegattaInfo();
  const [eventData] = useEventResult(eventNum);
  const trackingStations: TrackingStation[] = regattaData?.TrackingStations || [];

  const columnTitles = regattaData?.[Names.N_TITLES] || {};
  const dayList: string[] = regattaData?.[Names.N_DAY_LIST] || [];

  const getTitle = (name: string) => {
    if (name === 'Stroke/Cox') {
      return columnTitles.Stroke || `${t('Stroke')}/${t('Cox')}`;
    }
    return columnTitles[name] || t(name);
  };

  const finished = eventData[Names.N_FINISHED];
  const official = eventData[Names.N_OFFICIAL];
  const provisionalTimes = !finished && !official;
  const officialTimes = !provisionalTimes;
  const isSprint =
    (eventData[Names.N_RACE_TYPE] ? eventData[Names.N_RACE_TYPE] : regattaData?.[Names.N_RACE_TYPE]) === 'Sprint';

  const entries = useMemo(() => {
    const rawEntries = eventData.entries || [];
    // Progressions will assign an 'Empty' crew to lanes with no entrant.  Filter these out.
    const entriesToShow = rawEntries.filter((e) => e.Crew !== 'Empty');
    return entriesToShow;
  }, [eventData.entries]);

  if (regattaData === undefined || entries.length === 0) {
    return <></>;
  }

  let tableMargin = Math.trunc(props.tableWidth * 0.02);
  if (tableMargin <= 12) tableMargin = 0;
  const tableWidth = props.tableWidth - tableMargin * 2;
  const omitCols = tableWidth < 512 ? 2 : tableWidth < 800 ? 1 : tableWidth < 1024 ? 1 : 0;

  const timeWidth = props.dimensions[TimeTemplateShort]?.width || 0;
  const bowWidth = props.dimensions[BowTemplate]?.width || 0;
  const omitStroke = omitCols > 0;
  const omitStart = omitCols > 1;
  // Place	[Crew]	Bow	[Stroke/Cox] Start RawTime	Adjust	Time	Split
  // Extra width given to crew and stroke columns
  const widthAvail = tableWidth - (omitStart ? 0 : timeWidth) - bowWidth - trackingStations.length * timeWidth;

  const strokeWidth = omitStroke ? 0 : Math.max(timeWidth, Math.trunc(widthAvail * 0.3));
  const crewWidth = Math.max(timeWidth, widthAvail - strokeWidth);

  // console.log( 'tableWidth=' + tableWidth + ' widthAvail=' + widthAvail  );
  const rowStyle = {
    Crew: Object.assign({}, styles.tableRow, {
      width: crewWidth,
    }),
    Bow: Object.assign({}, styles.tableRow, {
      width: bowWidth,
    }),
    Stroke: Object.assign({}, styles.tableRow, { width: strokeWidth }),
    Start: Object.assign({}, styles.tableRow, {
      width: timeWidth,
    }),
    Waypoint: Object.assign({}, styles.tableRow, {
      width: timeWidth,
    }),
  };
  if (omitStroke) rowStyle.Stroke.display = 'none';
  if (omitStart) rowStyle.Start.display = 'none';

  const marginPx = `${tableMargin}px`;

  // clone rowStyle and add fontWeight bold
  const headerStyle: { [key: string]: React.CSSProperties } = {};
  Object.keys(rowStyle).forEach((item) => {
    headerStyle[item] = Object.assign({}, rowStyle[item], {
      fontWeight: 'bold',
      fontSize: '1em',
    });
  });

  const day = dayList.length > 1 ? `${eventData.Day || ''} ` : '';
  const startTime = eventData.Start;
  const totalCols = 2 + trackingStations.length + (omitStroke ? 0 : 1) + (omitStart ? 0 : 1);

  return (
    <div
      style={{
        pageBreakInside: props.tableNumber === 0 ? 'auto' : 'avoid',
        marginLeft: marginPx,
        marginRight: marginPx,
      }}
    >
      <Table style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow style={styles.headerRow}>
            <TableCell
              colSpan={totalCols - 2}
              style={{
                ...styles.tableSuperHeader,
                ...(tableWidth >= 800 ? {} : { fontSize: '1rem', lineHeight: '1rem' }),
              }}
            >
              <div>{`${day}${startTime}${startTime && ' '}${getTitle('Event')}: ${eventData.Event}`}</div>
              {eventData.EventInfo && (
                <div
                  style={{
                    ...styles.tableEventInfo,
                    fontSize: `${
                      tableWidth >= 800 ? 1 : Math.min(1, Math.max(0.7, 1 - 30 / (eventData.EventInfo.length + 1)))
                    }em`,
                  }}
                >{`${eventData.EventInfo}`}</div>
              )}
            </TableCell>
            <TableCell colSpan={2} style={styles.tableSuperHeaderRight}>
              <>
                {provisionalTimes && <div>{getTitle('Provisional Times')}</div>}
                {officialTimes && <div>{getTitle('Official')}</div>}
              </>
            </TableCell>
          </TableRow>
          <TableRow style={styles.headerRow}>
            <TableCell style={headerStyle.Crew}>{getTitle('Crew')}</TableCell>
            <TableCell style={headerStyle.Bow}>{isSprint ? getTitle('Lane') : getTitle('Bow')}</TableCell>
            <TableCell style={headerStyle.Stroke}>{`${getTitle('Stroke/Cox')}`}</TableCell>
            <TableCell style={headerStyle.Start}>{getTitle('Start')}</TableCell>
            {trackingStations.map((trackingStation) => {
              return (
                <TableCell key={`col_${trackingStation.name}`} style={headerStyle.Waypoint}>
                  {trackingStation.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((row, i) => {
            const startTimeString = row[Names.N_START_TIME];
            const stroke = row[Names.N_STROKE] || '';
            const crew =
              omitStroke && stroke.length > 0 ? `${row[Names.N_CREW]} (${row[Names.N_STROKE]})` : row[Names.N_CREW];
            const key = `${row[Names.N_EVENTNUM]}-${row[Names.N_BOW]}-${row[Names.N_CREW]}`;
            const bgStyle: React.CSSProperties = {
              background: i % 2 ? evenColor : oddColor,
              ...styles.tableRow,
            };
            return (
              <TableRow key={key} style={bgStyle}>
                <TableCell style={rowStyle.Crew}>{crew}</TableCell>
                <TableCell style={rowStyle.Bow}>{row[Names.N_BOW]}</TableCell>
                <TableCell style={rowStyle.Stroke}>{row[Names.N_STROKE]}</TableCell>
                <TableCell style={rowStyle.Start}>{formatTime(startTimeString)}</TableCell>
                {trackingStations.map((trackingStation) => {
                  const time = row[`C_${trackingStation.name}_time`];
                  return (
                    <TableCell key={trackingStation.name} style={{ ...rowStyle.Waypoint }}>
                      {formatTime(time)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default TrackingEventTable;
