import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unassigned-import
import 'github-markdown-css'; // for markdown-body className
import Markdown from './Markdown';

// Future - query doc content as markdocn via
// 'https://www.googleapis.com/drive/v3/files/1APKPlDculJLWYU8NcFCYlmJcmSpF5Csd?alt=media&key=AIzaSyBxl61gy473Yq7KDT_838HYPnRsfZz_Y5M'
//
interface PageToUrl {
  name: string;
  short: string;
  docId?: string;
  url?: string;
  content?: string; // filled after a query
  hidden?: boolean;
}

const pageToUrl: PageToUrl[] = [
  {
    name: 'Quick Start',
    short: 'QuickStart',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/QuickStart.md',
  },
  {
    name: 'Regatta Management',
    short: 'RegattaManagement',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/RegattaManagement.md',
  },
  {
    name: 'Timing Tips',
    short: 'TimingTips',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/TimingTips.md',
  },
  {
    name: 'Data Dictionary',
    short: 'DataDictionary',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/DataDictionary.md',
  },
  {
    name: 'Progressions',
    short: 'Progressions',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/progression-engine/ProgressionEngine.md',
  },
  {
    name: 'Live Stream Support',
    short: 'LiveStream',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/LivestreamOverlay.md',
  },
  {
    name: 'Legacy Live Stream Support',
    short: 'LegacyLiveStream',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/LegacyLivestream.md',
    hidden: true,
  },
  {
    name: 'Kiosk Mode',
    short: 'KioskMode',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/LivestreamOverlay.md',
    hidden: true,
  },
  {
    name: 'CrewTimer Video Review',
    short: 'VideoReview',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/video-review/ReviewingVideo.md',
    hidden: false,
  },
  {
    name: 'CrewTimer Video Recorder',
    short: 'VideoRecorder',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/video-recorder/VideoRecorder.md',
    hidden: false,
  },
  {
    name: 'Downloads',
    short: 'Downloads',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/Downloads.md',
    hidden: true,
  },
  {
    name: 'Equipment',
    short: 'Equipment',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/Equipment.md',
    hidden: true,
  },
  {
    name: 'FinishLynx',
    short: 'FinishLynx',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/finishlynx-connect/CrewTimerFinishLynx.md',
  },
  // {
  //   name: 'Mobile App Guide',
  //   short: 'MobileAppGuide',
  //   url:
  //     'https://docs.google.com/document/d/e/2PACX-1vS9qMrDwfEzCfPdcwDfQaCjqBOhVQC851iGHWS1lbSKHGikC57rt4u9rqotXF9y1dZOejKp1385B8rg/pub?embedded=true',
  // },
  {
    name: 'FAQ',
    short: 'FAQ',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/FAQ.md',
  },
  {
    name: 'Hardware Support',
    short: 'Hardware',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/Hardware.md',
  },
  {
    name: 'API',
    short: 'api',
    url: 'https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/API.md',
    hidden: true,
  },
];

const Help: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const selectPage = (link) => {
    const url = `/help/${link.short}`;
    navigate(url);
  };

  const fields = location.pathname.split('/');
  let urlpage = 'QuickStart';
  if (fields.length > 2) {
    urlpage = fields[2];
  }
  urlpage = urlpage.toLowerCase();

  const relIndex = window.location.pathname.indexOf('/help/');
  const relPath = relIndex >= 0 ? `${window.location.pathname.substring(relIndex + '/help/'.length)}` : '';
  let pageLink = pageToUrl.find((link) => urlpage === link.short.toLowerCase());
  if (!pageLink && location.pathname.endsWith('.md')) {
    pageLink = {
      name: relPath,
      short: relPath,
      url: `https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/${relPath}`,
    };
  }

  return (
    <div style={{ marginLeft: 16, marginRight: 16, display: 'flex', flexDirection: 'row' }}>
      <div className="noprint">
        <List component="nav">
          {pageToUrl
            .filter((link) => link.hidden !== true)
            .map((link) => (
              <ListItem button selected={urlpage === link.short} onClick={() => selectPage(link)} key={link.name}>
                <div>{link.name}</div>
              </ListItem>
            ))}
        </List>
      </div>
      <div style={{ flex: 1, marginLeft: 16, marginTop: 16 }} className="markdown-body">
        {!!pageLink ? <Markdown docId={pageLink.docId} url={pageLink.url} relPath={relPath} /> : 'Page not found'}
      </div>
    </div>
  );
};
export default Help;
