import React from 'react';
const Contact = () => {
  return (
    <div style={{ marginLeft: '30px', marginRight: '30px', marginTop: '0.5em' }}>
      <p>
        <em>
          <strong>Contact</strong>
        </em>
      </p>
      <p>
        CrewTimer support is available by contacting Glenn Engel at{' '}
        <a href="mailto:info@crewtimer.com">info@crewtimer.com</a> or by cell phone +1-425-405-0576.
      </p>
      <p>Suggestions, bug reports, and feedback are welcome.</p>
      <p>Regards,</p>
      <p>Glenn Engel</p>
    </div>
  );
};

export default Contact;
