import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CellEditor from './CellEditor';
import { useRegattaConfig, useStopwatchConfig } from '../shared/RegattaState';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((/* theme */) => ({
  textlabel: {
    whiteSpace: 'nowrap',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

/**
 * Dialogs can be nested. This example opens a Date Picker from within a Dialog.
 */
const StopwatchOptions = () => {
  const classes = useStyles();
  const [regattaConfig] = useRegattaConfig();
  const [stopwatchConfig, setStopwatchConfig] = useStopwatchConfig();

  if (regattaConfig === undefined) {
    return <></>;
  }
  const onStopwatchChange = (event, value) => {
    const config = { ...stopwatchConfig, useStopwatchTimes: value };
    setStopwatchConfig(config);
  };

  const onClockChange = (name, value) => {
    const config = { ...stopwatchConfig, clockTime: value };
    setStopwatchConfig(config);
  };

  const onStopwatchTimeChange = (name, value) => {
    const config = { ...stopwatchConfig, stopwatchTime: value };
    setStopwatchConfig(config);
  };

  return (
    <div>
      <FormControl>
        <FormControlLabel
          className={classes.textlabel}
          control={
            <Checkbox
              className={classes.checkbox}
              name="useStopwatchTimes"
              checked={stopwatchConfig.useStopwatchTimes}
              onChange={onStopwatchChange}
              color="primary"
            />
          }
          label="Stopwatch Data Entry"
        />
        <FormHelperText>Allows use of times read from a stopwatch.</FormHelperText>
      </FormControl>
      {stopwatchConfig.useStopwatchTimes && (
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <div style={{ fontWeight: 'bold' }}>Stopwatch Calibration</div>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ paddingLeft: '1em' }}>Stopwatch&nbsp;</div>
              </td>
              <td style={{ float: 'right' }}>
                <CellEditor
                  name="StopwatchValue"
                  editable={true}
                  defaultValue={stopwatchConfig.stopwatchTime}
                  prompt="Enter Stopwatch at clock HH:MM:SS.SSS"
                  regex="^([0-9]??[0-9]:)??([0-9]??([0-9]:)??[0-9])??[0-9]([.][0-9]??[0-9]??[0-9]??)??$"
                  onChange={onStopwatchTimeChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ paddingLeft: '1em' }}>At time&nbsp;</div>
              </td>
              <td style={{ float: 'right' }}>
                <CellEditor
                  name="StopwatchTime"
                  editable={true}
                  defaultValue={stopwatchConfig.clockTime}
                  prompt="Enter Stopwatch Clock HH:MM:SS.SSS"
                  regex="^[0-9][0-9]:[0-9][0-9]:[0-9][0-9][.][0-9][0-9][0-9]$"
                  onChange={onClockChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StopwatchOptions;
