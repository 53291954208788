/**
 * Created by glenne on 6/17/2017.
 */
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  container: {
    position: 'relative' as const,
    margin: '50',
  },
  refresh: {
    display: 'inline-block',
    position: 'relative' as const,
    left: 10,
    top: 0,
  },
  loading: {
    marginTop: '10',
  },
};

const LoadingIndicator = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 1200);
    return () => clearTimeout(timer);
  });
  return show ? (
    <div style={style.container}>
      <CircularProgress size={40} style={style.refresh} />
      <div style={style.loading}>Loading...</div>
    </div>
  ) : (
    <div />
  );
};

export default LoadingIndicator;
