import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo } from 'react';
import { useKiosk } from './UseKiosk';
import { useElapsedClock } from './UseElapsedClock';
import * as Names from './Names';
import { useSelectedRegatta, useIsEditable } from './RegattaState';
import { useTranslation } from 'react-i18next';
import { IconButton, SxProps, Theme } from '@mui/material';
import { EventSummary, useEventSummaryList, useRegattaInfo } from './UseResults';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { UseDatum } from 'react-usedatum';
import { getTitle } from './StringUtil';
import { decodeEventName, KeyMap } from 'crewtimer-common';
import { useLocation } from 'react-router';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: '2px',
    color: 'rgb(108, 125, 150)',
    backgroundColor: '#f5f5f5',
  },
  selectItem: {},
  selectAddItem: { backgroundColor: '#ffff99' },
  searchBar: {
    marginTop: '8px',
    background: 'white',
    paddingBottom: '0.5em',
    marginLeft: '1%',
    marginRight: '1%',
  },
  hideall: {
    margin: theme.spacing(2),
  },
  info: {
    margin: theme.spacing(3),
  },
  tableHeader: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    // height: "30px",
    color: 'black',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
}));

// Internal state of the dropdown selects
export const [useCrewFilter] = UseDatum('');
export const [useDayFilter] = UseDatum('');
export const [useStrokeFilter] = UseDatum('');
const [useFilteredRegatta] = UseDatum('');
export const [useSearchText] = UseDatum('');

//
// The following are exported to allow use in other views such as Results, Schedule, and Heatsheets.
//
/// The current Event Filter selection
export const [useEventFilter, _setEventFilter] = UseDatum('');
/// A list of the currently filtered items, including add-ons related to progressions
export const [useFilteredEventNums] = UseDatum<string[]>([]);
/// True if a filter is active
export const [useFilterActive, _setFilterActive] = UseDatum(false);
/// Event numbers that are added as related to the basic event filtering
export const [useRelatedEventNums] = UseDatum(new Set<string>());
/**
 * Set the Event Filter to a specific event
 *
 * @param eventNum
 */
export const gotoSingleEvent = (eventNum: string) => {
  _setEventFilter(eventNum);
};

const StyledTextField = styled(TextField)((/* { theme } */) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 4,
    padding: '1px 0px',
    '& fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& input': {
      padding: '4px 0px',
    },
  },
}));
const SearchField: React.FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  const [searchText, setSearchText] = useSearchText();

  const handleClear = () => {
    setSearchText('');
  };

  return (
    <StyledTextField
      placeholder="Search"
      variant="outlined"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value.toLowerCase())}
      sx={sx}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="clear search" onClick={handleClear} edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

/**
 * Monitor the selected regatta for a change.
 * If a change detected, reset all the event filters.
 *
 * @returns empty component
 */
const RegattaChangeMonitor = () => {
  const [regatta] = useSelectedRegatta();
  const [filteredRegatta, setFilteredRegatta] = useFilteredRegatta();
  const [, setCrewFilter] = useCrewFilter();
  const [, setEventFilter] = useEventFilter();
  const [, setDayFilter] = useDayFilter();
  const [, setStrokeFilter] = useStrokeFilter();
  const [, setFilterActive] = useFilterActive();
  const [, setSearchText] = useSearchText();
  useEffect(() => {
    if (regatta !== filteredRegatta) {
      setCrewFilter('');
      setEventFilter('');
      setDayFilter('');
      setStrokeFilter('');
      setFilterActive(false);
      setFilteredRegatta(regatta);
      setSearchText('');
    }
  }, [
    filteredRegatta,
    regatta,
    setCrewFilter,
    setDayFilter,
    setEventFilter,
    setFilterActive,
    setStrokeFilter,
    setFilteredRegatta,
    setSearchText,
  ]);
  return <></>;
};

/**
 * Render a row of results filters (stroke, crew, etc)
 *
 * When changes are made, the useFilteredEventNums and useFilterActive hooks are updated.
 *
 * @returns A row of filter selections
 */
export const EventFilter = () => {
  const classes = useStyles();
  const [kioskMode] = useKiosk();
  const [showElapsedClock] = useElapsedClock();
  const [crewFilter, setCrewFilter] = useCrewFilter();
  const [eventFilter, setEventFilter] = useEventFilter();
  const [dayFilter, setDayFilter] = useDayFilter();
  const [strokeFilter, setStrokeFilter] = useStrokeFilter();
  const [searchText] = useSearchText();
  const [eventSummaryList] = useEventSummaryList();
  const [regattaInfo] = useRegattaInfo();
  const location = useLocation();
  const { t } = useTranslation();
  const [editable] = useIsEditable();
  const [, setFilteredEventNums] = useFilteredEventNums();
  const [, setFilterActive] = useFilterActive();
  const [, setRelatedEventNums] = useRelatedEventNums();

  const _onDayFilterChange = (event) => {
    setDayFilter(event.target.value);
  };

  const _onFilterChange = (event) => {
    setStrokeFilter('');
    setCrewFilter(event.target.value);
    setEventFilter('');
  };

  const _onStrokeFilterChange = (event) => {
    setStrokeFilter(event.target.value);
    setCrewFilter('');
    setEventFilter('');
  };

  const _onEventFilterChange = (event) => {
    setEventFilter(event.target.value);
  };

  const popupMaxHeight = 600;
  let crews = new Set<string>();
  let strokes = new Set<string>();
  let crewList: string[] = [];
  let strokeList: string[] = [];
  const dayList: string[] = regattaInfo?.[Names.N_DAY_LIST] || [];
  const columnTitles = regattaInfo?.[Names.N_TITLES] || {};
  const omitSelect = (!editable && regattaInfo?.[Names.N_RESULT_OMIT_COLS]) || [];

  const removeParensRegex = /\([^()]*\)/g;

  eventSummaryList.forEach((event) => {
    crews = new Set([...crews, ...Array.from(event.crews).map((crew) => crew.replace(removeParensRegex, '').trim())]);
    strokes = new Set([
      ...strokes,
      ...Array.from(event.strokes).map((stroke) => stroke.replace(removeParensRegex, '').trim()),
    ]);
  });

  crewList = Array.from(crews).sort();
  strokeList = Array.from(strokes).sort();

  let filterCount = 1;
  const showDayFilter = dayList.length > 1;
  const showCrewFilter = !searchText && !showElapsedClock && crewList.length > 1;
  const showStrokeFilter = !searchText && !showElapsedClock && !omitSelect.includes('Stroke') && strokeList.length > 1;
  const showEventFilter =
    !searchText && !location.pathname.startsWith('/schedule/') && !location.pathname.startsWith('/heatsheet/');

  filterCount += showDayFilter ? 1 : 0;
  filterCount += showCrewFilter ? 1 : 0;
  filterCount += showStrokeFilter ? 1 : 0;
  filterCount += showEventFilter ? 1 : 0;
  const pctWidth = `${(99 - filterCount * 2) / filterCount}%`;

  let singleEvent: EventSummary | undefined;

  /**
   * Cache some cooked data for quick lookup when applying filters
   */
  const nameInfoByEventNum = useMemo(() => {
    const lookups: KeyMap<ReturnType<typeof decodeEventName>> = {}; // decoded event name
    eventSummaryList.forEach((event) => {
      lookups[event.EventNum] = decodeEventName(event.Event, event.EventNum);
    });

    return lookups;
  }, [eventSummaryList]);

  /// Events that meet the basic filter criteria
  let seededPickList: string[] = []; /// events which match the filter
  let finalPickList: string[] = []; /// events which match filter + future progressions
  const addedEventNums = new Set<string>();
  if (!strokeFilter && !crewFilter && !eventFilter && !dayFilter && !searchText) {
    // no filters
    seededPickList = finalPickList = eventSummaryList.map((event) => event.EventNum);
  } else {
    const addMatch = (eventNum: string) => {
      seededPickList.push(eventNum);
    };
    eventSummaryList.forEach((row) => {
      const eventNum = row.EventNum;
      if (dayFilter && row.Day !== dayFilter) {
        return;
      }
      if (!searchText && eventFilter && eventNum === eventFilter) {
        singleEvent = row;
      }
      if (searchText) {
        let match = row.Event.toLowerCase().includes(searchText) || row.EventNum.toLowerCase().includes(searchText);
        if (match) {
          addMatch(eventNum);
        } else {
          for (const crew of row.crews) {
            if (crew.toLowerCase().includes(searchText)) {
              match = true;
            }
          }
          if (!match) {
            for (const abbrev of row.crewAbbrevs) {
              if (abbrev.toLowerCase().includes(searchText)) {
                match = true;
              }
            }
          }
          if (!match) {
            for (const stroke of row.strokes) {
              if (stroke.toLowerCase().includes(searchText)) {
                match = true;
              }
            }
          }
          if (match) {
            addMatch(eventNum);
          }
        }
      } else if (crewFilter) {
        let match = false;
        const filterByLower = crewFilter.toLowerCase();
        (row.crews || []).forEach((crew) => {
          // const crew = entry.Crew.toLowerCase().replace(/ [A-Za-z]$/, '');
          crew = crew.toLocaleLowerCase().replace(removeParensRegex, '').trim();
          if (crew === filterByLower || (crew.includes(';') && crew.includes(filterByLower))) {
            match = true;
          }
        });
        if (match) {
          addMatch(eventNum);
        }
      } else if (strokeFilter) {
        let match = false;
        const filterByLower = strokeFilter.toLowerCase();
        (row.strokes || []).forEach((stroke) => {
          stroke = stroke.toLowerCase().replace(removeParensRegex, '').trim();
          if (stroke === filterByLower || (stroke.includes(';') && stroke.includes(filterByLower))) {
            match = true;
          }
        });
        if (match) {
          addMatch(eventNum);
        }
      } else {
        addMatch(eventNum);
      }
    });

    // Augment the pick list with related (progression) events

    const seededEvents = new Set<string>(); // A list of event names that are explicitly seeded
    seededPickList.forEach((eventNum) => {
      const info = nameInfoByEventNum[eventNum] || {};
      seededEvents.add(`${info.eventName}`);
      seededEvents.add(`${info.eventName}-${info.bracketType}`);
    });

    eventSummaryList.forEach((evt) => {
      const eventNum = evt.EventNum;
      if (seededPickList.includes(eventNum)) {
        // Already met filter, just keep and move on
        finalPickList.push(eventNum);
        return;
      }

      // If race is official, and it didn't meet the filter, exclude
      // If the day is specified, only show if day matches
      if (evt.Official || (dayFilter && evt.Day !== dayFilter)) {
        return;
      }

      // Only include the event if there is an entry in the event (seededEvents) and
      // the bracket has not already been included.  Assumes if one part of a bracket is
      // seeded then we should not add other brackets.  e.g. If we have an entry in SAB1
      // then we don't assume an entry in SAB2 on the premise SAB2 has already been seeded
      // and excluded as not meeting the filter.
      const info = nameInfoByEventNum[eventNum] || {};
      if (seededEvents.has(`${info.eventName}`) && !seededEvents.has(`${info.eventName}-${info.bracketType}`)) {
        finalPickList.push(eventNum);
        addedEventNums.add(eventNum);
      }
    });
  }

  const filteredEventNums = singleEvent ? [singleEvent.EventNum] : finalPickList;
  setTimeout(() => {
    if (!strokeFilter && !crewFilter && !eventFilter && !dayFilter && !searchText) {
      // no filters
      setFilteredEventNums(eventSummaryList.map((event) => event.EventNum));
      setRelatedEventNums(new Set<string>());
      setFilterActive(false);
    } else {
      setFilteredEventNums(filteredEventNums);
      setRelatedEventNums(addedEventNums);
      setFilterActive(true);
    }
  }, 10);

  if (kioskMode) {
    return <></>;
  }

  const onPriorEvent = () => {
    if (eventFilter === '') {
      if (finalPickList.length) {
        setEventFilter(finalPickList[finalPickList.length - 1]);
      }
      return;
    }
    const index = finalPickList.indexOf(eventFilter);
    if (index > 0) {
      setEventFilter(finalPickList[index - 1]);
    }
  };
  const onNextEvent = () => {
    if (eventFilter === '') {
      if (finalPickList.length) {
        setEventFilter(finalPickList[0]);
      }
      return;
    }
    const index = finalPickList.indexOf(eventFilter);
    if (index < 0) {
      setEventFilter(finalPickList[0]);
    } else if (index < finalPickList.length - 1) {
      setEventFilter(finalPickList[index + 1]);
    }
  };

  return (
    <div className={`${classes.searchBar} noprint`}>
      <RegattaChangeMonitor />
      <SearchField sx={{ width: pctWidth, maxWidth: '300px', marginLeft: '1%', marginRight: '1%' }} />
      {showDayFilter && (
        <Select
          className="noprint"
          variant="standard"
          displayEmpty
          value={dayFilter}
          onChange={_onDayFilterChange}
          style={{
            maxHeight: popupMaxHeight,
            width: pctWidth,
            fontSize: '1em',
            marginLeft: '1%',
            marginRight: '1%',
          }}
        >
          <MenuItem key="alldays" value="" className={classes.selectItem}>
            <em>{getTitle('All Days', columnTitles, t)}</em>
          </MenuItem>
          {dayList.map((row) => {
            const key = row;
            return (
              <MenuItem key={key} value={key} className={classes.selectItem}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      )}
      {showCrewFilter && (
        <Select
          className="noprint"
          variant="standard"
          displayEmpty
          value={crewFilter}
          onChange={_onFilterChange}
          style={{
            maxHeight: popupMaxHeight,
            width: pctWidth,
            fontSize: '1em',
            marginLeft: '1%',
            marginRight: '1%',
          }}
        >
          <MenuItem key="allcrews" value="" className={classes.selectItem}>
            <em>{getTitle('All Crews', columnTitles, t)}</em>
          </MenuItem>
          {crewList.map((row) => {
            const key = row;
            return (
              <MenuItem key={key} value={key} className={classes.selectItem}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      )}
      {showStrokeFilter && (
        <Select
          className="noprint"
          variant="standard"
          displayEmpty
          value={strokeFilter}
          onChange={_onStrokeFilterChange}
          style={{
            maxHeight: popupMaxHeight,
            width: pctWidth,
            fontSize: '1em',
            marginLeft: '1%',
            marginRight: '1%',
          }}
        >
          <MenuItem key="allstroke" value="" className={classes.selectItem}>
            <em>{getTitle('All Stroke/Cox', columnTitles, t)}</em>
          </MenuItem>
          {strokeList.map((row) => {
            const key = row;
            return (
              <MenuItem key={key} value={key} className={classes.selectItem}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      )}
      {showElapsedClock && (
        <IconButton size="large" onClick={onPriorEvent}>
          <NavigateBefore />
        </IconButton>
      )}
      {showEventFilter && (
        <Select
          className="noprint"
          variant="standard"
          displayEmpty
          value={eventFilter}
          onChange={_onEventFilterChange}
          style={{
            maxHeight: popupMaxHeight,
            width: pctWidth,
            fontSize: '1em',
            marginLeft: '1%',
            marginRight: '1%',
            textOverflow: 'ellipsis',
          }}
        >
          <MenuItem key="allevents" value="" className={classes.selectItem}>
            <em>{getTitle('All Events', columnTitles, t)}</em>
          </MenuItem>
          {finalPickList.map((eventNum) => {
            const row = eventSummaryList.find((event) => event.EventNum === eventNum) || { Event: '', Start: '' };
            return (
              <MenuItem
                key={eventNum}
                value={eventNum}
                className={addedEventNums.has(eventNum) ? classes.selectAddItem : classes.selectItem}
              >
                {addedEventNums.has(eventNum)
                  ? `+ ${row.Event}${row.Start ? ` ${row.Start}` : ''}`
                  : `${row.Event}${row.Start ? ` ${row.Start}` : ''}`}
              </MenuItem>
            );
          })}
        </Select>
      )}
      {showElapsedClock && (
        <IconButton size="large" onClick={onNextEvent}>
          <NavigateNext />
        </IconButton>
      )}
      <br />
    </div>
  );
};

export default EventFilter;
