/* eslint-disable import/no-unassigned-import */
/**
 * fire-dev.js - Development firebase configuration
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
const config = {
  apiKey: 'AIzaSyC7Xrxk35RL5yHQohd-NdDZ9E_natHNpB8',
  authDomain: 'crewtimer-results-dev.firebaseapp.com',
  databaseURL: 'https://crewtimer-results-dev.firebaseio.com',
  projectId: 'crewtimer-results-dev',
  storageBucket: 'crewtimer-results-dev.appspot.com',
  messagingSenderId: '64638108902',
};
const fire = firebase.initializeApp(config);
export const auth = fire.auth();
export const storage = fire.storage();
export const database = fire.database();
export default fire;
