import { UseDatum } from 'react-usedatum';
import { StopwatchConfig, TimeSystems, Lap, RegattaConfig } from './CrewTimerTypes';
import deepequal from 'fast-deep-equal/es6/react';
import Util from './Util';
import { useMemo, useEffect } from 'react';
import KeyMap from './KeyMap';

const DefaultStopwatch: StopwatchConfig = {
  useStopwatchTimes: false,
  clockTime: '08:00:00.000',
  stopwatchTime: '00:00.000',
};

export const [useIsEditable, setIsEditable, getIsEditable] = UseDatum(false);
export const [useShowAnalysis] = UseDatum(true);
export const [useShowScheduleOrder] = UseDatum(true); /// show start order when editing
export const [useShowClockTimes] = UseDatum(true);
export const [useShowStopwatchTimes] = UseDatum(false);
export const [useOfficialEntries, setOfficialEntries, getOfficialEntries] = UseDatum<{ [uuid: string]: Lap }>({});

export const [useSelectedRegatta, , getSelectedRegatta] = UseDatum<string>('', () => {
  setOfficialEntries({});
});
export const [useStopwatchConfig, setStopwatchConfig, getStopwatchConfig] = UseDatum<StopwatchConfig>(
  DefaultStopwatch,
  (current, prior) => {
    if (deepequal(current, prior)) {
      return;
    }

    const regatta = getSelectedRegatta();
    if (regatta) {
      Util.setRegattaConfig(regatta, { Stopwatch: current }).catch((e) => console.log(String(e)));
    }
  }
);

export const [useTimingWaypoints, setTimingWaypoints, getTimingWaypoints] = UseDatum<string[]>([]);
export const [useTimeSystems, setTimeSystems] = UseDatum<TimeSystems>({ system: {}, events: {} });

export const [useBaseTimingWaypoints, setBaseTimingWaypoints, getBaseTimingWaypoints] = UseDatum<{
  baseWaypoints: string[];
  baseAlternates: { [name: string]: string[] };
  baseLookup: { [name: string]: string };
}>({ baseWaypoints: [], baseAlternates: {}, baseLookup: {} });

export const [useRegattaConfig, setRegattaConfig, getRegattaConfig] = UseDatum<RegattaConfig | undefined>(
  undefined,
  (current, prior) => {
    // Trigger updates on subset of data if changed
    if (!deepequal(current?.Stopwatch, getStopwatchConfig)) {
      setStopwatchConfig(current?.Stopwatch || DefaultStopwatch);
    }
    if (!deepequal(current?.Waypoints, prior?.Waypoints)) {
      const timingWaypoints = current ? Util.getTimingWaypoints(current) : [];
      setTimingWaypoints(timingWaypoints);

      // Compute some values to assist with the UI for selecting timing systems
      const waypoints = ['Start', ...timingWaypoints, 'Finish'];
      const baseLookup: { [name: string]: string } = {};
      const alternateWaypoints = waypoints.filter((waypoint) =>
        waypoints.some((alt) => alt !== waypoint && waypoint.startsWith(alt))
      );

      let baseWaypoints = waypoints.filter((waypoint) => !alternateWaypoints.includes(waypoint));
      const baseAlternates: { [name: string]: string[] } = {};
      baseWaypoints.forEach((waypoint) => {
        const alternates = alternateWaypoints.filter((alt) => alt.startsWith(waypoint));
        alternates.forEach((alt) => (baseLookup[alt] = waypoint));
        if (alternates.length) {
          baseAlternates[waypoint] = [waypoint, ...alternates];
        }
      });

      // Only keep the ones that had alternates
      baseWaypoints = Object.keys(baseAlternates);

      setBaseTimingWaypoints({ baseWaypoints, baseAlternates, baseLookup });

      // if (current?.timeSystems !== undefined) {
      //   _setTimingSystems(current.timeSystems);
      // }
    }
  }
);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useFirebaseList = <T extends any = { [key: string]: any }>(
  regatta: string,
  root: string,
  path: string
) => {
  const [_useFirebaseList, setFirebaseList] = useMemo(() => UseDatum<T[]>([]), []);
  useEffect(() => {
    if (!regatta) {
      return;
    }
    const ref = Util.firebase.database().ref(`${root}/${regatta}/${path}`);
    ref.on('value', (snapshot) => {
      const result = snapshot.val();
      setFirebaseList(result);
    });
    return () => ref.off();
  }, [setFirebaseList, regatta, root, path]);
  return _useFirebaseList();
};

export const useFirebaseObject = <T extends KeyMap = KeyMap>(regatta: string, root: string, path: string) => {
  const [_useFirebaseList, setFirebaseList] = useMemo(() => UseDatum<T>({} as T), []);
  useEffect(() => {
    if (!regatta) {
      return;
    }
    const ref = Util.firebase.database().ref(`${root}/${regatta}/${path}`);
    ref.on('value', (snapshot) => {
      const result = snapshot.val();
      setFirebaseList(result);
    });
    return () => ref.off();
  }, [setFirebaseList, regatta, root, path]);
  return _useFirebaseList();
};
