import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useFirebaseObject } from './RegattaState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Switch,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { milliToString } from './TimeUtil';
import KeyMap from './KeyMap';
import { Entry } from './Entry';

const now = new Date();
const evenColor = '#f0f0f0';
const oddColor = '#ffffff';

const cleanList = (list: { [key: string]: any }[]) => {
  list.forEach((row) => {
    const gate = row.Gate || '';
    row.Gate = gate === 'S' ? 'Start' : gate === 'F' ? 'Finish' : gate.replace(/^G_/, '');
    const mmdd = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(row.Timestamp));
    row.ServerTime = `${mmdd}-${milliToString(
      (Number(row.Timestamp) - now.getTimezoneOffset() * 60 * 1000) % (24 * 60 * 60 * 1000)
    )}`;
    row.State = row.State === 'Deleted' ? row.State : '';
    row.ID = `${row.Gate}-${row.EventNum}-${row.Bow || ''}`;
  });
};
export const TransactionLog = () => {
  // const [selectedRegatta]= useSelectedRegatta();
  const [regatta, setRegatta] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [showChanges, setShowChanges] = useState(false);
  const [orderBy, setOrderBy] = useState('ServerTime');
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>('desc');
  const [search, setSearch] = useState('');
  const location = useLocation();
  const [lapdata] = useFirebaseObject(regatta, 'regatta', 'lapdata');
  const [journal] = useFirebaseObject(showDetail ? regatta : '', 'journal', 'lapdata');
  const list = Object.values(lapdata || []);
  const cols = ['ServerTime', 'Time', 'Gate', 'EventNum', 'Bow', 'State', 'src', 'PenaltyCode', 'PenaltyTime'];
  const colNames = {
    ServerTime: 'Server Rx',
    Gate: 'Station',
    EventNum: 'Event Num',
    Event: 'Event',
    src: 'Data Source',
    PenaltyCode: 'Code',
    PenaltyTime: 'Penalty Time',
  };

  let journalList: KeyMap[] = [];
  cleanList(list);
  if (showDetail) {
    journalList = Object.values(journal);
    cleanList(journalList);
  }

  useEffect(() => {
    const fields = location.pathname.split('/');
    if (fields.length >= 3) {
      setRegatta(fields[2]);
    }
  }, [location.pathname]);

  const createSortHandler = (col: string) => () => {
    if (orderBy === col) {
      setOrder(order !== 'asc' ? 'asc' : 'desc');
    } else {
      setOrder('asc');
      setOrderBy(col);
    }
  };

  const searchChange = (event) => {
    setSearch(event.target.value);
  };
  let displist = list;
  if (search) {
    const lowerSearch = search.toLowerCase();
    displist = list.filter((row) => {
      let found = false;
      cols.forEach((col) => {
        found = found || (row[col] || '').toLowerCase().includes(lowerSearch);
      });
      return found;
    });
  }

  if (orderBy) {
    const dir = order === 'asc' ? 1 : -1;
    displist = displist.sort((a, b) => {
      let sort = (a[orderBy] || '').localeCompare(b[orderBy] || '', undefined, { numeric: true }) * dir;
      if (sort === 0) {
        if (orderBy === 'Time') {
          sort = (a.ServerTime || '').localeCompare(b.ServerTime || '', undefined, { numeric: true }) * dir;
        } else {
          sort = (a.Time || '').localeCompare(b.Time || '', undefined, { numeric: true }) * dir;
        }
      }
      return sort;
    });
  }

  const showRow = (row: Entry) => {
    console.log(JSON.stringify(row, null, 2));
  };

  return (
    <>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch
            checked={showDetail}
            onChange={() => {
              setShowDetail(!showDetail);
            }}
            name="showAnalysis"
            color="primary"
          />
        }
        label="Show History"
        style={{ margin: 8 }}
      />
      <FormControlLabel
        labelPlacement="start"
        disabled={!showDetail}
        control={
          <Switch
            checked={showChanges}
            onChange={() => {
              setShowChanges(!showChanges);
            }}
            name="showAnalysis"
            color="primary"
          />
        }
        label="Mark Changes"
        style={{ margin: 8 }}
      />
      <TextField
        id="outlined-multiline-flexible"
        label="search"
        value={search}
        onChange={searchChange}
        defaultValue={search !== undefined ? undefined : 'search'}
        variant="outlined"
        size="small"
        style={{ margin: 8 }}
      />
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={col} sortDirection="asc">
                {colNames[col] || col}
                <TableSortLabel
                  active={orderBy === col}
                  direction={orderBy === col ? order : 'asc'}
                  onClick={createSortHandler(col)}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displist.map((row, rownum) => {
            let details: KeyMap[] = showDetail ? journalList.filter((entry) => entry.uuid === row.uuid) : [];
            if (showDetail && details.length > 1) {
              details = details.sort((a, b) => a.Timestamp - b.Timestamp);
              let lastRow: { [key: string]: any } = {};
              return details.map((detail, detailnum) => {
                const detailContent = (
                  <TableRow key={rownum + 200000 * detailnum} style={{ background: rownum % 2 ? evenColor : oddColor }}>
                    {cols.map((col, colnum) => {
                      let bgStyle = {};
                      if (showChanges && colnum >= 2 && detailnum > 0 && lastRow[col] !== detail[col]) {
                        bgStyle = { background: '#ff08' };
                      }
                      return (
                        <TableCell key={100000 + colnum} style={bgStyle}>
                          {detail[col]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );

                lastRow = detail;
                return detailContent;
              });
            } else
              return (
                <TableRow key={rownum} style={{ background: rownum % 2 ? evenColor : oddColor }}>
                  {cols.map((col, i) => (
                    <TableCell key={i} onClick={() => showRow(row)}>
                      {row[col]}
                    </TableCell>
                  ))}
                </TableRow>
              );
          })}
        </TableBody>
      </Table>
    </>
  );
};
