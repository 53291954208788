import React from 'react';
import Markdown from './Markdown';
const About = () => {
  return (
    <div style={{ flex: 1, paddingLeft: 16, paddingTop: 16, backgroundColor: '#fff' }} className="markdown-body">
      <Markdown url="https://raw.githubusercontent.com/crewtimer/crewtimer-doc/main/general/About.md" />
    </div>
  );
};
export default About;
