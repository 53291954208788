import CardWrapper from './CardWrapper';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemSecondaryAction, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import uuidgen from 'short-uuid';
import { useSelectedRegatta } from './shared/RegattaState';
import Util from './shared/Util';
import { auth, storage } from './fire';
import { useLogoURL } from './shared/UseResults';

const base64ImageToBlob = (base64Image: string) => {
  const byteString = atob(base64Image.split(',')[1]);
  const mimeString = base64Image.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const deleteImage = (uuid) => {
  const userId = auth?.currentUser?.uid;
  const path = `images/${userId}/${uuid}`;
  console.log(`Deleting image ${path}`);
  // Create a reference to the file to delete
  const imageRef = storage.ref(path);

  // Delete the file
  imageRef
    .delete()
    .then(() => {
      console.log('Image successfully deleted from Firebase Storage.');
      // You may want to update your component's state here to remove the image from the UI
    })
    .catch((error) => {
      console.error('Error removing image: ', error);
    });
};

const uploadImage = (file, uuid) => {
  return new Promise<string | undefined>((resolve) => {
    if (!file) {
      resolve(undefined);
      return;
    }
    const userId = auth?.currentUser?.uid;
    console.log('Storing image using uid=' + userId);

    // Initialize Firebase Storage
    // const storage = firebase.storage();

    const storageRef = storage.ref(`images/${userId}/${uuid}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optional: Handle upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Upload error:', error);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch((error) => {
            resolve(undefined);
            console.error('Error getting download URL:', error);
          });
      }
    );
  });
};

interface ImageItem {
  id: string;
  url: string;
}

const ImageListManager: React.FC = () => {
  const [images, setImages] = useState<ImageItem[]>([]);
  const [logoURL, setLogoURL] = useLogoURL();
  const [selectedRegatta] = useSelectedRegatta();
  const uid = Util.getUser()?.uid;

  useEffect(() => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const imagesRef = storage.ref().child(`images/${userId}`);
      setImages([]);
      imagesRef
        .listAll()
        .then((res) => {
          res.items.forEach((imageRef) => {
            // Get the download URL
            imageRef
              .getDownloadURL()
              .then((url) => {
                setImages((prevImages) => [...prevImages, { id: imageRef.name, url }]);
              })
              .catch((error) => {
                console.log(error);
              });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgElement = document.createElement('img');
        imgElement.src = e.target?.result as string;
        imgElement.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 400;
          const scaleSize = maxSize / Math.max(imgElement.width, imgElement.height);
          canvas.width = imgElement.width * scaleSize;
          canvas.height = imgElement.height * scaleSize;
          const ctx = canvas.getContext('2d');
          if (ctx && uid) {
            ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
            const src = canvas.toDataURL('image/png');
            const id = uuidgen.generate();

            const imageBlob = base64ImageToBlob(src);
            uploadImage(imageBlob, id)
              .then((url) => {
                if (url) {
                  setImages((prevImages) => [...prevImages, { id, url }]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (image: ImageItem) => {
    // Delete the image from Firebase Storage
    deleteImage(image.id);
    // Update local state to remove the image from the list
    setImages(images.filter((img) => img.id !== image.id));
    if (image.url === logoURL) {
      setLogoURL(''); // Reset active image if it's deleted
      handleSelectImage('');
    }
  };

  const handleSelectImage = (src: string) => {
    setLogoURL(src === logoURL ? '' : src); // Toggle active state
    Util.setRegattaConfig(selectedRegatta, { LogoURL: src === logoURL ? '' : src })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ fontSize: '12px', marginBottom: '8px' }}>
        Logo images are linked to your login and shared across your regattas. Use star icon to select.
      </Typography>
      <Button variant="contained" component="label">
        Upload Image
        <input type="file" hidden accept="image/*" onChange={handleImageChange} />
      </Button>
      <List>
        {images.map((image) => (
          <ListItem key={image.id} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={image.url}
              alt={`img-${image.id}`}
              style={{ maxWidth: '220px', maxHeight: '64px', width: 'auto', height: 'auto', marginRight: 16 }}
            />
            <ListItemSecondaryAction>
              <IconButton
                color={logoURL === image.url ? 'primary' : 'default'}
                edge="end"
                aria-label="set active"
                onClick={() => handleSelectImage(image.url)}
              >
                {logoURL === image.url ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(image)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const LogoCard = () => {
  return (
    <CardWrapper title="My Logo Images">
      <ImageListManager />
    </CardWrapper>
  );
};

export default LogoCard;
