/**
 * Created by glenne on 6/10/2017.
 */
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import fire from '../fire';
import EventTable from './EventTable';
import { KeyMap } from './KeyMap';
import LoadingIndicator from './LoadingIndicator';
import MeasureText from './MeasureText';
import Util from './Util';
import {
  useSelectedRegatta,
  useBaseTimingWaypoints,
  useShowAnalysis,
  useShowClockTimes,
  useShowStopwatchTimes,
  useIsEditable,
  useShowScheduleOrder,
} from './RegattaState';
import { useTranslation } from 'react-i18next';
import TimeSystemSelection from './TimeSystemSelection';
import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EventClock from './EventClock';
import { useElapsedClock } from './UseElapsedClock';
import { useEventSummaryList, useRegattaTitle, useResultsError } from './UseResults';
import LapEditorPopover from '../forms/LapEditor';
import { TableVirtuoso, VirtuosoHandle } from 'react-virtuoso';
import { TimeContextMenu } from './TimeContextMenu';
import { usePrintPending } from './UsePrintPending';
import { UseDatum } from 'react-usedatum';
import EventFilter, { useFilterActive, useFilteredEventNums } from './EventFilter';
import TrackingEventTable from './TrackingEventTable';
import { useLocation } from 'react-router-dom';
import ChangeTracker, { useKioskEvent, useResultsPending, useNumEvents } from './ChangeTracker';

Util.setFirebase(fire);

const TimeTemplate = '00:00:00.000';
const TimeTemplateShort = '00:00:00';
const BowTemplate = '000';
const PlaceTemplate = 'Place';

const tableRowStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  fontSize: '1em',
  fontWeight: 'normal',
  height: '30px',
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: '2px',
    color: 'rgb(108, 125, 150)',
    backgroundColor: '#f5f5f5',
  },
  selectItem: {},
  searchBar: {
    marginTop: '8px',
    background: 'white',
    paddingBottom: '0.5em',
  },
  hideall: {
    margin: theme.spacing(2),
  },
  info: {
    margin: theme.spacing(3),
  },
  tableHeader: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontSize: '1em',
    fontWeight: 'bold',
    // height: "30px",
    color: 'black',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  },
}));

const [usePageDimensions] = UseDatum<KeyMap>({});

/**
 * Render event results as a cell within a larger Table containing all the events.
 *
 * @param param
 * @returns
 */
const EventTableCell = ({ index, eventNum }: { index: number; eventNum: string }) => {
  const [regatta] = useSelectedRegatta();
  const [eventSummaryList] = useEventSummaryList();
  const [dimensions] = usePageDimensions();
  const location = useLocation();
  const trackingPage = location.pathname.startsWith('/tracking/');
  const row = eventSummaryList.find((event) => event.EventNum === eventNum);

  if (!row) {
    return <></>;
  }

  const tableWidth = window.innerWidth;
  const TableRenderer = trackingPage ? TrackingEventTable : EventTable;
  return (
    <TableCell key={row.EventNum} sx={{ border: 0 }}>
      <Box sx={{ paddingTop: '1em', paddingBottom: '0.5em' }}>
        <TableRenderer
          regatta={regatta}
          eventNum={row.EventNum}
          width={tableWidth}
          tableWidth={tableWidth}
          dimensions={dimensions}
          tableNumber={index}
        />
      </Box>
    </TableCell>
  );
};

/**
 * Render a Table of event results
 *
 * @returns A Table of event results
 */
const EventTableList = () => {
  const [filteredEventNums] = useFilteredEventNums();
  const [renderAll] = usePrintPending();
  const [kioskEvent] = useKioskEvent();
  const virtuoso = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    setTimeout(() => {
      const index = filteredEventNums.findIndex((eventNum) => eventNum === kioskEvent);
      // console.log(`Scrolling to ${eventNum}.  Found index=${index}`);
      if (index >= 0) {
        virtuoso.current?.scrollToIndex(index);
      }
    }, 100);
  }, [filteredEventNums, kioskEvent]);

  if (renderAll) {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            {filteredEventNums.map((eventNum, index) => {
              return (
                <TableRow key={index}>
                  <EventTableCell index={index} eventNum={eventNum} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableVirtuoso
      ref={virtuoso}
      style={{ flex: 1, width: '100%' }}
      data={filteredEventNums}
      useWindowScroll={true}
      components={{
        // Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
        Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
        TableHead,
        TableRow,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      }}
      itemContent={(index, eventNum) => {
        // console.log(`Rendering ${key}`);
        return <EventTableCell index={index} eventNum={eventNum} />;
      }}
    />
  );
};

const ElapsedClockView = () => {
  const [filteredEventNums] = useFilteredEventNums();
  return <EventClock eventNum={filteredEventNums[0]} />;
};

const NoEntriesMessage = () => {
  const { t } = useTranslation();
  const [filterActive] = useFilterActive();
  const [filteredEventNums] = useFilteredEventNums();

  return filteredEventNums.length === 0 && filterActive ? <div>{t('No Entries match filter')}</div> : <></>;
};
/**
 * Render the main Results page.
 *
 * Upon initial rendering, the page is measured along with estimates of text
 * sizes to compute column widths.  Column widths determine what columns to
 * perhaps hide or combine given the space available.
 *
 * In additon the page renders with a 'ChangeTracker' component to watch for
 * changes that may affect the entire page and require a re-render.
 *
 * @returns
 */
const EventResults = () => {
  const classes = useStyles();
  const [dimensions, setDimensions] = usePageDimensions();
  const { t } = useTranslation();
  const [error] = useResultsError();
  const [showElapsedClock] = useElapsedClock();
  const [showAnalysis, setShowAnalysis] = useShowAnalysis();
  const [showClockTimes, setShowClockTimes] = useShowClockTimes();
  const [showStopwatchTimes, setShowStopwatchTimes] = useShowStopwatchTimes();
  const [{ baseAlternates }] = useBaseTimingWaypoints();
  const [editable] = useIsEditable();
  const [showScheduleOrder, setShowScheduleOrder] = useShowScheduleOrder();
  const [regattaTitle] = useRegattaTitle();
  const [resultsPending] = useResultsPending();
  const [numEvents] = useNumEvents();

  const hasAlternates = Object.keys(baseAlternates).length !== 0;

  const updateDimensions = (dims: KeyMap) => {
    // defer setDimxx to avoid updating while MeasureText is rendering
    setTimeout(() => {
      setDimensions(dims);
    }, 0);
  };

  if (Object.keys(dimensions).length === 0) {
    return (
      <div>
        <MeasureText
          text={[PlaceTemplate, BowTemplate, TimeTemplate, TimeTemplateShort]}
          onMeasure={updateDimensions}
          textStyle={tableRowStyle}
        />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  type PageWrapProps = React.PropsWithChildren<{}>;
  const PageWrap: React.FC<PageWrapProps> = ({ children }) => {
    return (
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <ChangeTracker />
        {children}
      </Box>
    );
  };

  if (error) {
    return (
      <PageWrap>
        <Typography variant="h6">{error}</Typography>
      </PageWrap>
    );
  }

  if (!regattaTitle || numEvents === -1) {
    return (
      <PageWrap>
        <LoadingIndicator />
      </PageWrap>
    );
  }

  if (numEvents === 0) {
    return (
      <PageWrap>
        <div className={classes.info}>
          <Typography variant="h6">
            This Regatta has not been successfully configured with a lineup spreadsheet.
          </Typography>
          <Typography variant="h6">1. Try using 'Refresh Lineups' to load the spreadsheet.</Typography>
          <Typography variant="h6">
            2. Ensure your spreadsheet permissions allow 'Anyone with the link' and then Refresh Lineups.
          </Typography>
          <Typography variant="h6">
            3. Ensure your spreadsheet has values for Bow, EventNum, EventName, and Crew.
          </Typography>
        </div>
      </PageWrap>
    );
  }
  const hideAll = !editable && resultsPending;
  if (hideAll) {
    return (
      <PageWrap>
        <Typography className={classes.hideall}>{t('Results Pending')}</Typography>
      </PageWrap>
    );
  }

  return (
    <PageWrap>
      {editable && (
        <div
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            marginTop: 16,
            marginLeft: 16,
            marginBottom: 8,
            borderBottom: '1px solid #888',
          }}
        >
          <FormControlLabel
            labelPlacement="start"
            label={`${t('Order')}:`}
            style={{ marginRight: '1em' }}
            control={
              <Select
                className="noprint"
                variant="standard"
                displayEmpty
                value={String(showScheduleOrder)}
                onChange={(event) => setShowScheduleOrder(event.target.value === 'true')}
                style={{
                  marginLeft: '0.5em',
                }}
              >
                <MenuItem key="schedule" value="true" className={classes.selectItem}>
                  {t('Schedule')}
                </MenuItem>

                <MenuItem key="place" value="false" className={classes.selectItem}>
                  {t('Place')}
                </MenuItem>
              </Select>
            }
          />

          {hasAlternates && (
            <>
              <TimeSystemSelection />
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={showAnalysis}
                    onChange={() => {
                      setShowAnalysis(!showAnalysis);
                    }}
                    name="showAnalysis"
                    color="primary"
                  />
                }
                label="Analyze"
              />
            </>
          )}

          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={showClockTimes}
                onChange={() => {
                  setShowClockTimes(!showClockTimes);
                  setShowStopwatchTimes(false);
                }}
                name="showClock"
                color="primary"
              />
            }
            label="Clock Times"
          />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={showStopwatchTimes}
                onChange={() => {
                  setShowStopwatchTimes(!showStopwatchTimes);
                  setShowClockTimes(false);
                }}
                name="showStopwatch"
                color="primary"
              />
            }
            label="Stopwatch Times"
          />
          {showAnalysis && (
            <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography style={{ backgroundColor: '#0f08', marginLeft: '1em' }}>0&lt;=700ms</Typography>
              <Typography style={{ backgroundColor: '#ff08' }}>&nbsp;&lt;=1200ms</Typography>
              <Typography style={{ backgroundColor: '#f00c' }}>&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
            </div>
          )}
        </div>
      )}
      <NoEntriesMessage />
      {/* {!showElapsedClock && kioskGreen && <div style={{ marginTop: '16px' }} />} */}
      {showElapsedClock && <ElapsedClockView />}
      <ChangeTracker />
      <LapEditorPopover />
      <EventFilter />
      <EventTableList />
      <TimeContextMenu />
    </PageWrap>
  );
};

export default EventResults;
