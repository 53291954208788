import React from 'react';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { createRoot } from 'react-dom/client';
import './i18next';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<App />);

unregister();
