import ActionDelete from '@mui/icons-material/Delete';
import ContentClear from '@mui/icons-material/Clear';
import NavigationRefresh from '@mui/icons-material/Refresh';
import ActionRowing from '@mui/icons-material/Rowing';
import ImageTimer from '@mui/icons-material/Timer';
import CloudDownload from '@mui/icons-material/CloudDownload';
import EditorModeEdit from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HistoryIcon from '@mui/icons-material/History';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useInfoText, useRegattaInfo, useRegattaTitle } from './shared/UseResults';
import { useRegattaConfig, getSelectedRegatta, useSelectedRegatta } from './shared/RegattaState';
import { useNavigate } from 'react-router';
import React, { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import {
  N_FINISHED,
  N_NUM_ENTRIES,
  N_POINTS_ENGINE,
  N_PUBLIC,
  N_RESULTS_PENDING,
  N_RESULTS_PEND_OFFICIAL,
} from './shared/Names';
import Util from './shared/Util';
import { useToast } from './shared/Toast';
import { LoadingButton } from '@mui/lab';
import { setDialogConfig } from './shared/ConfirmDialog';
import { UseDatum } from 'react-usedatum';
import { PointsViewers } from 'crewtimer-points';
import CardWrapper from './CardWrapper';
import LogoCard from './LogoCard';

const [useLoadingButton, setLoadingButton] = UseDatum('');
interface ActionButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  text: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  sx?: SxProps<Theme>;
}
const ActionButton = (props: ActionButtonProps) => {
  const [loadingButton] = useLoadingButton();
  const theme = useTheme();
  return (
    <LoadingButton
      onClick={props.onClick}
      loading={loadingButton === props.text}
      size="small"
      variant="contained"
      color={props.color || 'primary'}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      sx={{ margin: theme.spacing(1), ...(props.sx ? props.sx : {}) }}
    >
      {props.text}
    </LoadingButton>
  );
};
export const InfoRow = ({ title, text, row }: { title: string; text: string; row: number }) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: '0.25em',
          paddingBottom: '0.25em',
          paddingRight: '1em',
          whiteSpace: 'nowrap',
          borderTop: row === 0 ? 'none' : 'thin solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography sx={{ textAlign: 'right' }}>{title}</Typography>
      </Box>
      <Box
        sx={{
          paddingTop: '0.25em',
          paddingBottom: '0.25em',
          paddingRight: '1em',
          paddingLeft: '1em',
          borderTop: row === 0 ? 'none' : 'thin solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography sx={{ textAlign: 'left' }}>{text}</Typography>
      </Box>
    </>
  );
};

export const CheckboxRow = ({
  title,
  keyname,
  row,
  tooltip,
}: {
  title: string;
  keyname: string;
  row: number;
  tooltip?: string;
}) => {
  const [regattaConfig] = useRegattaConfig();
  const [, setToast] = useToast();
  const curValue = regattaConfig?.[keyname] || false;
  const checked = curValue === true || curValue === 'true';
  const onChange = (event, value: boolean) => {
    if (
      keyname === N_PUBLIC &&
      !(regattaConfig?.DataSource === 'RegattaMaster') &&
      regattaConfig?.DocUrl ===
        'https://docs.google.com/spreadsheets/d/1K2UWYS9Vfb4HlHtMGRi5pGRWj4CM9ZrKNbWo58vdvh4/edit?usp=sharing'
    ) {
      setToast('Unable to make public using demo spreadsheet');
    } else {
      Util.setRegattaConfig(getSelectedRegatta(), { [keyname]: value }).catch((reason) => {
        setToast(`Unable to set '${keyname}': ${String(reason)}`);
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          paddingTop: '0.25em',
          paddingBottom: '0.25em',
          paddingRight: '1em',
          whiteSpace: 'nowrap',
          borderTop: row === 0 ? 'none' : 'thin solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {tooltip ? (
          <Tooltip title={tooltip}>
            <Typography sx={{ textAlign: 'right' }}>{title}</Typography>
          </Tooltip>
        ) : (
          <Typography sx={{ textAlign: 'right' }}>{title}</Typography>
        )}
      </Box>
      <Box
        sx={{
          paddingTop: '0.25em',
          paddingBottom: '0.25em',
          paddingLeft: '1em',
          borderTop: row === 0 ? 'none' : 'thin solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox name={keyname} checked={checked} onChange={onChange} color="primary" sx={{ padding: 0 }} />
      </Box>
    </>
  );
};
export const SelectFormField = ({
  name,
  keyname,
  selects,
  row,
}: {
  name: string;
  keyname: string;
  selects: string[][];
  row: number;
}) => {
  const [regattaConfig] = useRegattaConfig();
  const curValue = regattaConfig?.[keyname] || 'None';
  const [, setToast] = useToast();
  const onSelectChange = (event: SelectChangeEvent<{ name?: string | undefined; value: string }>) => {
    Util.setRegattaConfig(getSelectedRegatta(), { [keyname]: event.target.value }).catch((reason) => {
      setToast(`Unable to set '${keyname}': ${String(reason)}`);
    });
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: '1em',
          paddingBottom: '0.25em',
          width: '100%',
          borderTop: row === 0 ? 'none' : 'thin solid rgba(0, 0, 0, 0.12)',
          display: 'flex',
          alignItems: 'left',
          gridColumnStart: 'span 2',
          textAlign: 'left',
        }}
      >
        <FormControl fullWidth margin="dense">
          <InputLabel id="select-label">{name}</InputLabel>

          <Select
            id="select-id"
            variant="outlined"
            value={curValue}
            labelId="select-label"
            label={name}
            onChange={onSelectChange}
            SelectDisplayProps={{ style: { padding: '6.5px 14px' } }}
          >
            {selects.map((item) => (
              <MenuItem key={item[0]} value={item[1]}>
                {item[0]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export const CardTitle: React.FC<PropsWithChildren> = (props) => {
  return (
    <Typography variant="h6" component="h2">
      {props.children}
    </Typography>
  );
};

const InfoCard = () => {
  const [regattaId] = useSelectedRegatta();
  const [regattaInfo] = useRegattaInfo();
  const [regattaConfig] = useRegattaConfig();
  const [title] = useRegattaTitle();
  const [infoText] = useInfoText();
  const navigate = useNavigate();
  let row = 0;
  return (
    <CardWrapper title="Configuration">
      <InfoRow title="Title" text={title} row={row++} />
      <InfoRow title="Info Text" text={infoText} row={row++} />
      <InfoRow
        title="Date"
        text={`${regattaInfo?.Date}${Number(regattaInfo?.NumDays) >= 1 ? ` (${regattaInfo?.NumDays} days)` : ''}`}
        row={row++}
      />
      <InfoRow title="Race Type" text={regattaInfo?.RaceType || ''} row={row++} />
      <InfoRow title="MobileID" text={regattaId} row={row++} />
      <InfoRow title="MobilePin" text={regattaConfig?.MobileKey || ''} row={row++} />
      <InfoRow title="Entries" text={String(regattaInfo?.[N_NUM_ENTRIES]) || ''} row={row++} />
      <Box sx={{ gridColumnStart: 'span 2' }}>
        <ActionButton
          text="QR Codes"
          startIcon={<QrCode2Icon />}
          onClick={() => {
            navigate('/qrcodes/' + regattaId);
          }}
        />
      </Box>
      <Box sx={{ gridColumnStart: 'span 2' }}>
        <ActionButton
          text="Configure"
          startIcon={<EditorModeEdit />}
          onClick={() => {
            navigate('/edit/' + regattaId);
          }}
        />
      </Box>
    </CardWrapper>
  );
};

const ActionCard = () => {
  const [, setToast] = useToast();
  const [regattaTitle] = useRegattaTitle();
  const [regattaConfig] = useRegattaConfig();
  const navigate = useNavigate();
  const refreshLineups = () => {
    setLoadingButton('Refresh Lineups');
    Util.reloadCsv(getSelectedRegatta())
      .then((result) => {
        setLoadingButton('');
        if (result.status === 'OK') {
          setToast('Lineups have been refreshed');
        } else {
          setDialogConfig({
            title: 'Reload Lineups Failed',
            message: result.status,
            button: 'OK',
            showCancel: false,
          });
        }
        // console.log('reload complete');
      })
      .catch((reason) => {
        setLoadingButton('');
        setDialogConfig({
          title: 'Reload Lineups Failed',
          message: String(reason),
          button: 'OK',
          showCancel: false,
        });
      });
  };
  const clearMobileApp = () => {
    setDialogConfig({
      confirmText: 'Mobile',
      handleConfirm: () => {
        setLoadingButton('Clear Mobile App');
        Util.clearLapData(getSelectedRegatta(), true)
          .then(() => {
            setLoadingButton('');
            setToast('Mobile devices cleared');
          })
          .catch((reason) => {
            setLoadingButton('');
            setToast('Error:' + reason);
          });
      },
      title: `Clear Mobile App for '${regattaTitle}' (${getSelectedRegatta()})`,
      message:
        "This action will clear all race data from the mobile app and does NOT affect cloud data.  If you proceed, your mobile app will be RESET back to it's initial state.",
      button: 'Clear Mobile',
      showCancel: true,
    });
  };
  const clearRaceData = () => {
    setDialogConfig({
      confirmText: 'Clear',
      handleConfirm: () => {
        setLoadingButton('Clear Race Data');
        Util.clearLapData(getSelectedRegatta(), false)
          .then(() => {
            setLoadingButton('');
            setToast('Clear complete');
          })
          .catch((reason) => {
            setLoadingButton('');
            setToast('Error:' + reason);
          });
      },
      title: `Clear Race Data for '${regattaTitle}' (${getSelectedRegatta()})`,
      message:
        "This action will clear all race data and is not recoverable.  If you proceed, your regatta will be RESET back to it's initial state.",
      button: 'Clear',
      showCancel: true,
    });
  };
  return (
    <CardWrapper title="Actions" cols={1}>
      <ActionButton text="Refresh Lineups" onClick={refreshLineups} startIcon={<NavigationRefresh />} color="success" />
      <ActionButton
        text="Configure"
        startIcon={<EditorModeEdit />}
        onClick={() => {
          navigate('/edit/' + getSelectedRegatta());
        }}
      />
      <ActionButton
        text="Open Spreadsheet"
        startIcon={<EditorModeEdit />}
        endIcon={<OpenInNewIcon />}
        onClick={() => {
          // navigate('/edit/' + regattaId);
          if (regattaConfig?.DocUrl) {
            window.open(regattaConfig.DocUrl, '_blank');
          }
        }}
      />
      <Divider variant="middle" />
      <ActionButton text="Clear Mobile App" onClick={clearMobileApp} startIcon={<ContentClear />} />
      <ActionButton text="Clear Race Data" onClick={clearRaceData} startIcon={<ContentClear />} color="error" />
      {/* <Divider variant="middle" /> */}
    </CardWrapper>
  );
};
const AdminCard = () => {
  const [, setToast] = useToast();
  const [regattaTitle] = useRegattaTitle();
  const navigate = useNavigate();
  const [, setSelectedRegatta] = useSelectedRegatta();

  const deleteRegatta = () => {
    setDialogConfig({
      confirmText: 'Delete',
      handleConfirm: () => {
        setLoadingButton('Delete');
        Util.deleteRegatta(getSelectedRegatta())
          .then(() => {
            setLoadingButton('');
            setSelectedRegatta('');
            setToast('Regatta deleted');
            navigate('/', { replace: true });
          })
          .catch((reason) => {
            setLoadingButton('');
            setToast('Error:' + reason);
          });
      },
      title: `Delete '${regattaTitle}' (${getSelectedRegatta()})`,
      message:
        'This action will delete the regatta and is not recoverable.  If you proceed, your regatta will be PERMANENTLY ERASED from CrewTimer.',
      button: 'Delete',
      showCancel: true,
    });
  };
  return (
    <CardWrapper title="Admin" cols={1}>
      <ActionButton
        text="Schedule"
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open('https://dev.crewtimer.com/schedule/' + getSelectedRegatta(), '_blank');
          } else {
            window.open('https://www.crewtimer.com/schedule/' + getSelectedRegatta(), '_blank');
          }
        }}
        startIcon={<CalendarMonthIcon fontSize="small" />}
        endIcon={<OpenInNewIcon />}
      />
      <ActionButton
        text="Heatsheet"
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open('https://dev.crewtimer.com/heatsheet/' + getSelectedRegatta(), '_blank');
          } else {
            window.open('https://www.crewtimer.com/heatsheet/' + getSelectedRegatta(), '_blank');
          }
        }}
        startIcon={<ListAltIcon fontSize="small" />}
        endIcon={<OpenInNewIcon />}
      />
      <ActionButton
        text="Registration Packet"
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open('https://dev.crewtimer.com/packet/' + getSelectedRegatta(), '_blank');
          } else {
            window.open('https://www.crewtimer.com/packet/' + getSelectedRegatta(), '_blank');
          }
        }}
        startIcon={<ListAltIcon fontSize="small" />}
        endIcon={<OpenInNewIcon />}
      />
      <CardTitle>Other</CardTitle>
      <ActionButton
        text="Transaction Log"
        startIcon={<HistoryIcon />}
        onClick={() => {
          navigate('/log/' + getSelectedRegatta());
        }}
      />
      <ActionButton
        text="Results"
        startIcon={<CloudDownload />}
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open('https://dev.crewtimer.com/results?asfile=true&regatta=' + getSelectedRegatta(), '_self');
          } else {
            window.open('https://www.crewtimer.com/results?asfile=true&regatta=' + getSelectedRegatta(), '_self');
          }
        }}
      />
      <ActionButton
        text="Heatsheet"
        startIcon={<CloudDownload />}
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open('https://dev.crewtimer.com/heatsheet?asfile=true&regatta=' + getSelectedRegatta(), '_self');
          } else {
            window.open('https://www.crewtimer.com/heatsheet?asfile=true&regatta=' + getSelectedRegatta(), '_self');
          }
        }}
      />
      <ActionButton
        text="Heatsheet (Fullnames)"
        startIcon={<CloudDownload />}
        onClick={() => {
          if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
            window.open(
              'https://dev.crewtimer.com/heatsheet?fullname=true&asfile=true&regatta=' + getSelectedRegatta(),
              '_self'
            );
          } else {
            window.open(
              'https://www.crewtimer.com/heatsheet?fullname=true&asfile=true&regatta=' + getSelectedRegatta(),
              '_self'
            );
          }
        }}
      />
      <ActionButton text="Delete" color="error" startIcon={<ActionDelete />} onClick={deleteRegatta} />
    </CardWrapper>
  );
};
const QuickConfig = () => {
  const navigate = useNavigate();
  const pointsSelects = PointsViewers.filter((viewer) => !viewer.name.startsWith('Basic')).map((viewer) => [
    viewer.name,
    viewer.key,
  ]);

  let row = 0;
  return (
    <CardWrapper title="Results">
      <CheckboxRow title="Public Visible" tooltip="Show the races on crewtimer.com" keyname={N_PUBLIC} row={row++} />
      <CheckboxRow
        title="All racing finished"
        tooltip="Marks all races as official and prevents updates from the mobile app."
        keyname={N_FINISHED}
        row={row++}
      />
      <CheckboxRow
        title="Hide finish times till official"
        tooltip="Hides the final times until a race has been marked 'Official'"
        keyname={N_RESULTS_PEND_OFFICIAL}
        row={row++}
      />
      <CheckboxRow
        title="Hide all lineups and results"
        tooltip="Hide the results and lineups on the website"
        keyname={N_RESULTS_PENDING}
        row={row++}
      />
      <CheckboxRow
        title="Show running clock (beta)"
        keyname="ShowProgress"
        row={row++}
        tooltip="Show elapsed time for each entry while race is in progress"
      />
      <SelectFormField
        name="Points Engine"
        selects={[['None', 'None'], ...pointsSelects]}
        keyname={N_POINTS_ENGINE}
        row={row++}
      />
      <Link href="https://github.com/crewtimer/crewtimer-points" target="_blank" rel="noopener">
        Add your own points engine
      </Link>
      <Box sx={{ gridColumnStart: 'span 2' }}>
        <ActionButton
          text="View Results"
          onClick={() => {
            if (window.location.hostname.match(/dev.crewtimer.com/) || window.location.hostname.match(/localhost/)) {
              window.open('https://dev.crewtimer.com/regatta/' + getSelectedRegatta(), '_blank');
            } else {
              window.open('https://www.crewtimer.com/regatta/' + getSelectedRegatta(), '_blank');
            }
          }}
          startIcon={<ActionRowing />}
          endIcon={<OpenInNewIcon />}
        />
      </Box>
      <Box sx={{ gridColumnStart: 'span 2' }}>
        <ActionButton
          text="Adjust Results"
          startIcon={<ImageTimer />}
          onClick={() => {
            navigate('/results-edit/' + getSelectedRegatta());
          }}
        />
      </Box>
    </CardWrapper>
  );
};

// const cleanList = (list: KeyMap[]) => {
//   const now = new Date();
//   list.forEach((row) => {
//     const gate = row.Gate || '';
//     row.Gate = gate === 'S' ? 'Start' : gate === 'F' ? 'Finish' : gate.replace(/^G_/, '');
//     row.ServerTime = milliToString(
//       (Number(row.Timestamp) - now.getTimezoneOffset() * 60 * 1000) % (24 * 60 * 60 * 1000)
//     );
//     row.State = row.State === 'Deleted' ? row.State : '';
//     row.ID = `${row.Gate}-${row.EventNum}-${row.Bow || ''}`;
//   });
// };

// const cols = ['Time', 'Gate', 'EventNum', 'Bow', 'State'];
// const colNames = {
//   ServerTime: 'Server Rx',
//   Gate: 'Station',
//   EventNum: 'Event',
//   src: 'Data Source',
//   PenaltyCode: 'Code',
//   PenaltyTime: 'Penalty Time',
// };

// const evenColor = '#f0f0f0';
// const oddColor = '#ffffff';
// const ActivityLog = () => {
//   const [selectedRegatta] = useSelectedRegatta();
//   const [lapdata] = useFirebaseObject(selectedRegatta, 'regatta', 'lapdata');
//   let list = Object.values(lapdata || []);
//   list = list.sort((a, b) => {
//     const sort = (a.ServerTime || '').localeCompare(b.ServerTime);
//     return sort;
//   });
//   list = list.slice(-10) as KeyMap[];
//   cleanList(list);
//   return (
//     <CardWrapper title="Activity Log">
//       <Box sx={{ gridColumnStart: 'span 2' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               {cols.map((col) => (
//                 <TableCell key={col} sortDirection="asc">
//                   <Typography fontSize="0.75rem">{colNames[col] || col}</Typography>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {list.map((row, rownum) => {
//               return (
//                 <TableRow key={rownum} style={{ background: rownum % 2 ? evenColor : oddColor }}>
//                   {cols.map((col, i) => (
//                     <TableCell key={i}>
//                       {' '}
//                       <Typography fontSize="0.75rem">{row[col]}</Typography>
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </Box>
//     </CardWrapper>
//   );
// };

const ManageRegatta = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
        //   /* This is better for small screens, once min() is better supported */
        //   /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
        gap: '1rem',
        padding: '1rem',
      }}
    >
      <ActionCard />
      <InfoCard />
      <QuickConfig />
      <AdminCard />
      <LogoCard />
    </Box>
  );
};

export default ManageRegatta;
