import { KeyMap } from 'crewtimer-common';

export function sanitizeFirebaseKey(s: string) {
  return s.replace(/[#$/[.\]]/g, '-');
}

export function sanitizeKeys(arg: any) {
  if (arg === undefined || arg === null) {
    return;
  }
  const t = typeof arg;
  if (Array.isArray(arg)) {
    arg.map((item) => sanitizeKeys(item));
  } else if (t === 'object') {
    const keys = Object.keys(arg);
    keys.forEach((key) => {
      const newKey = sanitizeFirebaseKey(key);
      const value = arg[key];
      if (newKey !== key) {
        arg[newKey] = value;
        delete arg[key];
      }
      sanitizeKeys(value);
    });
  }
}

export function niceWaypoint(waypoint: string | undefined) {
  if (!waypoint) {
    return '';
  }
  waypoint = waypoint
    .replace(/^G_/, '')
    .replace(/^S$/, 'Start')
    .replace(/^F$/, 'Finish')
    .replace(/^Pen$/, 'Penalty')
    .replace(/_/g, ' ');
  return waypoint;
}

export const getTitle = (name: string, columnTitles: KeyMap<string>, t: (string) => string) => {
  if (name === 'Stroke/Cox') {
    return columnTitles.Stroke || `${t('Stroke')}/${t('Cox')}`;
  } else if (name === 'All Crews') {
    const suffix = columnTitles?.Crew?.endsWith('s') ? '' : 's'; // only append s if not already ending in s
    return columnTitles.Crew ? `${t('All')} ${columnTitles.Crew}${suffix}` : `${t('All')} ${t('Crew')}s`;
  } else if (name === 'All Stroke/Cox') {
    return columnTitles.Stroke ? `${t('All')} ${columnTitles.Stroke}` : `${t('All')} ${t('Stroke')}/${t('Cox')}`;
  }
  const title = columnTitles[name] || t(name) || name;
  return title;
};
